import {Component, Input, OnInit} from '@angular/core';
import {BibchipHttpService} from "../other/services/bibchip.http.service";
import {UserService} from "../other/services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Result, ResultCompetition, ResultEvent} from "../other/model/resultEvent";
import {NavigationService} from "../other/services/navigation.service";

@Component({
  selector: 'app-result-event',
  templateUrl: './result-event.component.html',
  styleUrls:['./result-event.component.scss']
})
export class ResultEventComponent implements OnInit {

  private eventId: number;
  public event: ResultEvent;

  constructor(private httpService: BibchipHttpService, private userService: UserService, private router: Router, private activatedRoute: ActivatedRoute, public navigationService: NavigationService) {
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      if(params.has('id')) {
        this.eventId = <number><unknown>params.get('id');
        this.loadEvent();
      }
    });
  }

  loadEvent() : void {
    this.httpService.get<ResultEvent>('results/' + this.eventId).subscribe(x => {
      this.event = x;
      this.navigationService.lastNavigationAction().subscribe(link => {
        if(!link) {
          this.navigationService.addNavigationAction('/results', this.event.name);
        }
      }).unsubscribe();
    });
  }

  getFirstCatResults(cat: string) {
    return function (res: Result) {
      return res.categoryName == cat && [1,2,3].indexOf(res.categoryPlace) > -1;
    };
  }

  shouldHaveResults() {
    return function (comp: ResultCompetition) {
      return comp.numberOfParticipants > 0;
    };
  }
}
