<alert></alert>
<mat-vertical-stepper *ngIf="!error && !isFinished" [linear]="true" #stepper (selectionChange)="switchStep($event)">
  <mat-step [stepControl]="basicInfo.form">
    <form  #basicInfo="ngForm" class="form">
      <ng-template matStepLabel><span translate>BasicInfo</span></ng-template>
      <mat-form-field class="full-width">
        <input matInput name="lastname" placeholder="{{ 'Lastname' | translate }}" [(ngModel)]="participant.lastName" required>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput name="firstname" placeholder="{{ 'Firstname' | translate }}"  [(ngModel)]="participant.firstName"  required>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput name="email" placeholder="{{ 'Email' | translate }}"  [(ngModel)]="participant.email" email required>
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-label><span translate>Gender</span></mat-label>
        <mat-select [(ngModel)]="participant.gender" name="gender" (selectionChange)="filterCompetitionsAndCategories()" required>
          <mat-option *ngFor="let gender of genders | async" [value]="gender.value">
            {{gender.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput [matDatepicker]="birthDatePicker" (dateChange)="filterCompetitionsAndCategories()" required placeholder="{{ 'Birthdate' | translate }}" name="birthDate"  [(ngModel)]="participant.birthDate" >
        <mat-datepicker-toggle class="big-icon" [for]="birthDatePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #birthDatePicker></mat-datepicker>
        <mat-hint><span translate>Format</span>: dd/MM/jjjj (<span translate>ForExample</span>: 26/11/1978)</mat-hint>
      </mat-form-field>
      <div>
        <button mat-raised-button color="primary" matStepperNext><span translate>Next</span></button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="addressInfo.form">
    <form #addressInfo="ngForm"  class="form">
      <ng-template matStepLabel><span translate>AddressInfo</span></ng-template>
      <mat-form-field class="two-third-of-width">
        <input matInput placeholder="{{ 'Street' | translate }}"  [(ngModel)]="participant.street" name="street"  required>
      </mat-form-field>
      <mat-form-field class="third-of-width margin-5">
        <input matInput placeholder="{{ 'HouseNumber' | translate }}"  [(ngModel)]="participant.houseNumber" name="housenumber"  required>
      </mat-form-field>
      <mat-form-field class="third-of-width">
        <input matInput placeholder="{{ 'PostalCode' | translate }}"  [(ngModel)]="participant.postalCode" name="postalcode"  required>
      </mat-form-field>
      <mat-form-field class="two-third-of-width margin-5">
        <input matInput placeholder="{{ 'City' | translate }}"  [(ngModel)]="participant.city" name="city"  required>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="{{ 'Country' | translate }}"  [(ngModel)]="participant.country" name="country"  required>
      </mat-form-field>
      <div>
        <button mat-raised-button matStepperPrevious><span translate>Previous</span></button>
        <button mat-raised-button color="primary" matStepperNext><span translate>Next</span></button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="competitionCategory.form" id="categoryStep">
    <form #competitionCategory="ngForm" class="form">
      <ng-template matStepLabel><span translate>SelectCompetitionAndCategory</span></ng-template>
      <mat-form-field class="full-width">
        <mat-label><span translate>Competition</span></mat-label>
        <mat-select name="competition" [(ngModel)]="selectedCompetition" (selectionChange)="filterCompetitionsAndCategories()" required>
          <mat-option *ngFor="let comp of competitions" [value]="comp">
            {{comp.name}}
          </mat-option>
        </mat-select>
        <mat-error>
          <div *ngIf="!competitions || competitions.length == 0">
            <span translate>NoCompetitionsAvailableWithYourParameters</span>
          </div>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label><span translate>Category</span></mat-label>
        <mat-select [disabled]="selectedCompetition == null" name="category" [(ngModel)]="participant.category" required>
          <mat-option *ngFor="let cat of categories" [value]="cat">
            {{cat.name }} ({{ cat.competition.name }})
          </mat-option>
        </mat-select>
        <mat-error>
          <div *ngIf="!categories || categories.length == 0">
            <span translate>NoCategoriesWithYourParametersAvailable</span>
          </div>
        </mat-error>
      </mat-form-field>
      <div>
        <button mat-raised-button matStepperPrevious><span translate>Previous</span></button>
        <button mat-raised-button color="primary" matStepperNext><span translate>Next</span></button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="extraFieldsForm.form" *ngIf="participant.category  && getDefaultExtraFields(this.participant.category).length > 0">
    <form #extraFieldsForm="ngForm" class="form">
      <ng-template matStepLabel><span translate>ExtraFields</span></ng-template>
        <ng-container *ngFor="let ef of getDefaultExtraFields(this.participant.category)">
          <app-extra-field
            *ngIf="!ef.onlyVisibleWhenThisIsFilledIn || checkExtraFieldValue(ef)"
            [required]="ef.isRequired"
            name="extrafield-{{ef.id}}"
            [(ngModel)]="getCorrectExtraFieldData(ef).value"
            [extraField]="ef"></app-extra-field>
        </ng-container>
    </form>
      <div>
        <button mat-raised-button matStepperPrevious><span translate>Previous</span></button>
        <button mat-raised-button color="primary" matStepperNext><span translate>Next</span></button>
      </div>
  </mat-step>
  <ng-container *ngIf="participant.category">
  <mat-step [stepControl]="stepForm.form" *ngFor="let step of getStepNames(participant.category)">
    <form #stepForm="ngForm" class="form">
    <ng-template matStepLabel>{{ step }}</ng-template>
        <div *ngFor="let ef of getStepExtraFields(step, participant.category)">
          <app-extra-field *ngIf="!ef.onlyVisibleWhenThisIsFilledIn || checkExtraFieldValue(ef)"
                           [required]="ef.isRequired" name="extrafield-{{ef.id}}"
                           [(ngModel)]="getCorrectExtraFieldData(ef).value"
                           [extraField]="ef"></app-extra-field>
        </div>
    <div>
      <button mat-raised-button matStepperPrevious><span translate>Previous</span></button>
      <button mat-raised-button color="primary" matStepperNext><span translate>Next</span></button>
    </div>
    </form>
  </mat-step>
  </ng-container>
  <mat-step *ngIf="event && event.paymentMethod" [label]="'Payment' | translate">
    <!-- payment was succesful (frontend) -->
    <div *ngIf="payment == 'success'">
      <span class="payment-successful" translate>PaymentSuccessful</span>
    </div>
    <div *ngIf="payment == 'fail'">
      <span class="payment-fail" translate>PaymentWentWrong</span>
    </div>

    <!-- payment was not executed yet or failed -> give choices -->
    <div *ngIf="!payment || payment != 'success'">
      <span *ngIf="isPaymentMethodActive(paypal)">
        <button  mat-raised-button color="primary" (click)="save(paypal)"><span translate>Paypal</span></button>
        <br />
        <br />
        </span>
      <div *ngIf="isPaymentMethodActive(bancontact)">
        <button  mat-raised-button color="primary" (click)="save(bancontact)"><span translate>Bancontact</span></button>
        <div *ngIf="bancontactImage" >
          <br />
          <img alt="pay-with-bancontact" [src]="bancontactImage" />
        </div>
        <div *ngIf="bancontactDeeplink">
          <br />
          <a  [href]="bancontactDeeplink">Open de app</a>
        </div>
        <br />
        <br />
      </div>
      <span *ngIf="isPaymentMethodActive(manual)">
         <button mat-raised-button color="primary" (click)="save(manual)"><span translate>Manual</span></button>
        <br />
         <br />
      </span>
      <button mat-raised-button matStepperPrevious><span translate>Previous</span></button>
    </div>

    <div>
      <button  *ngIf="payment == 'success'" mat-raised-button matStepperNext><span translate>Next</span></button>
    </div>


    <div>
      <br />
      <br />
      <button *ngIf="participant.isPreview" mat-raised-button matStepperNext color="accent"><span translate>ONLY_IN_PREVIEW</span>&nbsp;<span translate>Next</span></button>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel><span translate>Complete</span></ng-template>

    <div>
      <button mat-raised-button matStepperPrevious *ngIf="!participant.wizardFinished"><span translate>Previous</span></button>
      <button mat-raised-button color="accent" *ngIf="event && !event.paymentMethod" (click)="save()"><span translate>Save</span></button>
      <button mat-raised-button color="accent" *ngIf="event && event.paymentMethod" (click)="finishWizard()"><span translate>Complete</span></button>
    </div>
  </mat-step>
</mat-vertical-stepper>
