import {Component, OnDestroy, OnInit } from '@angular/core';
import {BibchipHttpService} from "../../../other/services/bibchip.http.service";
import {UserService} from "../../../other/services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent, ConfirmDialogModel} from "../../../other/shared/confirm-dialog/confirm-dialog.component";
import {OrganiserEvent} from "../../../other/model/organiserEvent";
import {NavigationService} from "../../../other/services/navigation.service";
import {DatePipe} from "@angular/common";
import {Subscription} from "rxjs";
import {OrganiserCompetition} from "../../../other/model/organiserCompetition";
import {OrganiserManageCompetitionComponent} from "./manage-competition.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-organiser-competition-overview',
  templateUrl: './competition-overview.component.html',
  styleUrls:['./competition-overview.component.scss'],
  providers:[DatePipe]
})
export class OrganiserCompetitionOverviewComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = [ 'name', 'distance','maxNumberOfParticipants', 'actions'];
  public competitions: MatTableDataSource<OrganiserCompetition>;
  public event: OrganiserEvent;
  private subscriptions: Subscription[];

  constructor(public dialog: MatDialog, private httpService: BibchipHttpService, private userService: UserService, private activatedRoute: ActivatedRoute, private router: Router, public navigationService: NavigationService, private datePipe: DatePipe, private translateService : TranslateService) {
    this.subscriptions = [];
  }

  add() {
    const dialogRef = this.dialog.open(OrganiserManageCompetitionComponent);
    dialogRef.componentInstance.loadCompetition(new OrganiserCompetition(), this.event.id);
    dialogRef.afterClosed().subscribe(x => { this.loadCompetitions(); });
  }

  deleteConfirmation(comp: OrganiserCompetition): void {

    const dialogData = new ConfirmDialogModel("ConfirmDelete","AreYouSureYouWantToDeleteX", comp.name);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult)
        this.delete(comp);
    });
  }

  edit(comp: OrganiserCompetition) {
    const dialogRef = this.dialog.open(OrganiserManageCompetitionComponent);
    dialogRef.componentInstance.loadCompetition(comp, this.event.id);
    dialogRef.afterClosed().subscribe(x => { this.loadCompetitions(); });
  }

  delete(comp: OrganiserCompetition): void {
    this.httpService.delete("organiser/events/" + this.event.id + '/competitions/' + comp.id).subscribe(x => {
      this.loadCompetitions();
    });
  }

  loadCompetitions() {
    this.httpService.get<OrganiserCompetition[]>("organiser/events/" + this.event.id + "/competitions").subscribe(x => {
      this.competitions = new MatTableDataSource<OrganiserCompetition>(x);
    });
  }

  ngOnInit(): void {
    this.subscriptions.push(this.activatedRoute.paramMap.subscribe(x => {
      let evId = x.get('id');
      this.httpService.get<OrganiserEvent>('organiser/events/' + evId).subscribe(y => {
        this.event = y;
        this.loadCompetitions();
         this.navigationService.lastNavigationAction().subscribe(link => {
          if(!link) {
            this.navigationService.addNavigationAction('/organiser/events/' + evId + '/dashboard', y.name + ' (' + this.translateService.instant('Competitions') + ')');
          }
        }).unsubscribe();
      });
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }
}
