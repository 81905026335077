export const distinct = (value, index, self) => {
  return self.indexOf(value) === index;
};

function distinctList<T>(items: T[], distinctVal: (T) => (any)) {
  if(!items)
    return items;
  let newList = [];
  items.forEach(x => {
    if(newList.map(y => distinctVal(y)).indexOf(distinctVal(x)) == -1) {
      newList.push(x);
    }
  });
  return newList;
}

export { distinctList }
