<div>
  <div *ngIf="title" class="title">
    <h4>{{ title }}</h4>
  </div>
  <div class="outside-content-container">
    <ng-content select="[outside-content-container]"></ng-content>
  </div>
  <div class="content-container">
    <ng-content></ng-content>
  </div>
</div>
