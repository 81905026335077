import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {BibchipHttpService} from "../../../other/services/bibchip.http.service";
import {UserService} from "../../../other/services/user.service";
import {MatDialogRef} from "@angular/material/dialog";
import {OrganiserCompetition} from "../../../other/model/organiserCompetition";
import {OrganiserCategory, Range, RangeType} from "../../../other/model/organiserCategory";
import {GenderObject, GenderService} from "../../../other/services/gender.service";
import {Observable} from "rxjs";

@Component({
  selector: 'app-organiser-manage-category',
  templateUrl: './manage-category.component.html',
  styleUrls:['./manage-category.component.scss']
})
export class OrganiserManageCategoryComponent implements OnInit {

  public category: OrganiserCategory;
  private eventId: number;
  public competitions: OrganiserCompetition[];
  public genders: Observable<GenderObject[]>;
  public ageRangeFilter: (range: Range) => boolean;

  constructor(private genderService: GenderService, public dialogRef: MatDialogRef<OrganiserManageCategoryComponent>, private httpService: BibchipHttpService, private userService: UserService, private router: Router) {
    this.category = new OrganiserCategory();
    this.competitions = [];
    this.ageRangeFilter = (range) => range.type == RangeType.Age;
  }

  ngOnInit(): void {

  }

  addAgeRange() : void {
    let range = new Range();
    range.addedOn = Date.now();
    range.type = RangeType.Age;
    this.category.ranges.push(range);
  }

  loadCategory(cat: OrganiserCategory, eventId: number): void {
    this.category = cat;
    this.eventId = eventId;
    this.genders = this.genderService.getGenderList(true, false);

    this.httpService.get<OrganiserCompetition[]>("organiser/events/" + this.eventId + "/competitions").subscribe(x => {
      this.competitions = x;
      this.category.organiserCompetition = x.filter(x => x.id == this.category.competitionId)[0];
    });
  }

  updateId() : void {
    this.category.competitionId = this.category.organiserCompetition.id;
  }

  add() : void {
    if(!this.category.id || this.category.id == 0) {
      this.httpService.post("organiser/events/" + this.eventId + "/categories", this.category).subscribe(x => {
        this.dialogRef.close();
      });
    } else {
      this.httpService.put("organiser/events/" + this.eventId + "/categories/" + this.category.id, this.category).subscribe(x => {
        this.dialogRef.close();
      });
    }
  }

  removeRange(range: Range) {
    this.category.ranges.splice(this.category.ranges.indexOf(range), 1);
  }
}
