import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {BibchipHttpService} from "../../../other/services/bibchip.http.service";
import {UserService} from "../../../other/services/user.service";
import {MatDialogRef} from "@angular/material/dialog";
import {ExtraFieldType, OrganiserExtraField} from "../../../other/model/organiserExtraField";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";

@Component({
  selector: 'app-organiser-manage-extra-field',
  templateUrl: './manage-extra-field.component.html',
  styleUrls:['./manage-extra-field.component.scss']
})
export class OrganiserManageExtraFieldComponent implements OnInit {

  public extraField: OrganiserExtraField;
  private eventId: number;
  public types: any;
  public listType: ExtraFieldType;
  public dependentOn: boolean;
  public allFields: OrganiserExtraField[];
  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(public dialogRef: MatDialogRef<OrganiserManageExtraFieldComponent>, private httpService: BibchipHttpService, private userService: UserService, private router: Router) {
    this.types = ExtraFieldType;
    this.extraField = new OrganiserExtraField();
    this.extraField.type = ExtraFieldType.Text;
    this.listType = ExtraFieldType.List;
  }

  addListItem(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.extraField.listItems.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeFromList(item: string): void {
    const index = this.extraField.listItems.indexOf(item);
    if (index >= 0) {
      this.extraField.listItems.splice(index, 1);
    }
  }

  ngOnInit(): void {
    this.log();
  }

  loadExtraField(field: OrganiserExtraField, eventId: number): void {
    this.extraField = field;
    this.eventId = eventId;
    if(!this.extraField.listItems)
      this.extraField.listItems = [];
    this.dependentOn = this.extraField.onlyVisibleWhenThisIsFilledIn != null;
    this.httpService.get<OrganiserExtraField[]>("organiser/events/" + this.eventId + "/extra-fields").subscribe(x => {
      this.allFields = x;
      if( this.extraField.position && this.extraField.position != 0)
        this.allFields = this.allFields.filter(y => y.position < this.extraField.position);
      if(this.extraField.onlyVisibleWhenThisIsFilledIn)
        this.extraField.onlyVisibleWhenThisIsFilledIn = x.filter(y => y.id == this.extraField.onlyVisibleWhenThisIsFilledIn.id)[0];
    });
  }

  add() : void {
    if(!this.extraField.id || this.extraField.id == 0) {
      this.httpService.post("organiser/events/" + this.eventId + "/extra-fields", this.extraField).subscribe(x => {
        this.dialogRef.close();
      });
    } else {
      this.httpService.put("organiser/events/" + this.eventId + "/extra-fields/" + this.extraField.id, this.extraField).subscribe(x => {
        this.dialogRef.close();
      });
    }
  }

  log() {
    console.log(this.extraField);
  }
}
