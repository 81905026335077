import {AfterViewInit, Component, OnInit } from '@angular/core';
import {BibchipHttpService} from "../../../other/services/bibchip.http.service";
import {MatDialogRef} from "@angular/material/dialog";
import { OrganiserEvent} from "../../../other/model/organiserEvent";
import {TranslateService} from "@ngx-translate/core";
import {PaymentService} from "../../../other/services/payment.service";

@Component({
  selector: 'app-organiser-publish',
  templateUrl: './publish.component.html',
  styleUrls:['./publish.component.scss']
})
export class OrganiserPublishComponent implements OnInit, AfterViewInit {

  public event: OrganiserEvent;
  public checklist: any[];

  constructor(private translateService: TranslateService, private paymentService: PaymentService, public dialogRef: MatDialogRef<OrganiserPublishComponent>, private httpService: BibchipHttpService) {
    this.event = new OrganiserEvent();
  }

  ngOnInit(): void {

  }

  loadEvent(e: OrganiserEvent): void {
    this.event = e;
    this.httpService.get<any[]>('organiser/events/' + this.event.id + '/publishable').subscribe(x => {
      this.checklist = x;
    });
  }

  publish() : void {
    this.httpService.post('organiser/events/' + this.event.id + '/publish', null).subscribe(x => {
      this.dialogRef.close();
    });
  }

  ngAfterViewInit(): void {

  }

  allDone(checklist: any[]) : boolean {
    return this.checklist && this.checklist.filter(x => !x.done).length == 0;
  }

  toggle(item: any) {
    if(item.canBeOverridden)
      item.done = !item.done;
  }
}
