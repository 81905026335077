import { Constants } from './../other/shared/constants';
import { map } from 'rxjs/operators';
import {ChangeDetectorRef, Component, OnDestroy, ViewChild} from '@angular/core';
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {Router} from "@angular/router";
import {UserService} from "../other/services/user.service";
import {Observable, Subscription} from "rxjs";
import {MatSidenav} from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import {NavigationService} from "../other/services/navigation.service";
import {Roles} from "../other/model/roles";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-alternate-nav-menu',
  templateUrl: './alternate-nav-menu.component.html',
  styleUrls: ['./alternate-nav-menu.component.scss']
})
export class AlternateNavMenuComponent implements OnDestroy {
  navLinks: any[];
  adminLinks: any[];
  organiserLinks: any[];

  activeLinkIndex = -1;
  subscriptions: Subscription[];
  private readonly loginLogout: any;

  public isAdmin: Observable<boolean>;
  public isOrganiser: Observable<boolean>;

  public showAdminMenu: boolean;
  public showOrganiserMenu: boolean;
  public isSignedIn: boolean;

  viewportMobileQuery: MediaQueryList;
  private readonly _viewportQueryListener: () => void;
  public fullScreenRequested: boolean;

  public canMenuBeOpenedOnSide: Observable<boolean>;
  public isMenuBeOpenedOnSide: Observable<boolean>;

  @ViewChild('appSideNav', {static: true})
  public sideNav: MatSidenav;

  constructor(private translateService: TranslateService, private router: Router, private userService: UserService,private changeDetectionRef: ChangeDetectorRef, private media: MediaMatcher, public navigationService: NavigationService) {
    this.viewportMobileQuery = media.matchMedia('(max-width: ' + Constants.breakpoint +'px)');
    this._viewportQueryListener = () =>  {
      changeDetectionRef.detectChanges();
      this.updateMenuObservables();
    }
    this.viewportMobileQuery.addEventListener('change', this._viewportQueryListener);
    this.subscriptions = [];
    this.subscriptions.push(this.navigationService.fullScreenRequested().subscribe(x => {
        this.fullScreenRequested = x;
        this.updateMenu();
    }));
    this.loginLogout =  {
      label: 'SignIn',
      icon: 'sign-in-alt',
      link: './signin',
      index: 3
    };
    this.navLinks = [
      {
        label: 'Home',
        link: './home',
        icon: 'stopwatch',
        index: 0
      }, {
        label: 'Results',
        link: './results',
        icon:'medal',
        index: 1,
      }, {
        label: 'Presubscriptions',
        icon: 'user-edit',
        link: './subscribe',
        index: 2
      }, this.loginLogout
    ];

    this.adminLinks = [ {
      label: 'Users',
      icon: 'users-cog',
      link: './admin/users',
      index: 10
    }];

    this.organiserLinks = [{
      label: 'ManageMyEvents',
      icon: 'bars',
      link: './organiser/events',
      index: 10
    }];

    this.updateMenuObservables();
    this.isAdmin = this.userService.isAdmin();
    this.isOrganiser = this.userService.isOrganiser();
    this.subscriptions.push(this.userService.isSignedIn().subscribe(x => {
      this.isSignedIn = x;
    }));
  }

  updateMenuObservables(): void {
    this.canMenuBeOpenedOnSide = this.userService.isSignedIn().pipe(map(x => x || this.viewportMobileQuery.matches));
    this.isMenuBeOpenedOnSide = this.canMenuBeOpenedOnSide.pipe(map(x => x && !this.viewportMobileQuery.matches));
  }

  ngOnInit(): void {

    this.subscriptions.push(this.userService.isSignedIn().subscribe(x => {
      if(x) {
        this.loginLogout.label = "SignOut";
        this.loginLogout.icon = "sign-out-alt";
        this.loginLogout.action = () => this.userService.signOut();
      } else {
        this.loginLogout.label = "SignIn";
        this.loginLogout.icon = "sign-in-alt";
        this.loginLogout.action = null;
      }
    }));

    this.router.events.subscribe((res) => {
      this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => tab.link === '.' + this.router.url));
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
    this.viewportMobileQuery.removeEventListener('change', this._viewportQueryListener);
  }

  navClicked(link: any) {
    this.navigationService.clearNavigation();
    this.updateMenu();
    if(link.action) {
      link.action();
    }
  }

  changeLanguage(lang: string) {
    localStorage.setItem('LANGUAGE', lang);
    this.translateService.use(lang).subscribe(x => {});
  }

  private updateMenu() {
    if((this.viewportMobileQuery.matches || this.fullScreenRequested) && this.sideNav)
      this.sideNav.close();
    else if(this.sideNav && this.isSignedIn)
      this.sideNav.open();
  }
}
