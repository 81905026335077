import { MatStepper } from '@angular/material/stepper';
import {Component, OnDestroy, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {BibchipHttpService} from "../other/services/bibchip.http.service";
import {NavigationService} from "../other/services/navigation.service";
import {ActivatedRoute, Router} from "@angular/router";
import {
  SubscriptionCategory,
  SubscriptionCompetition,
  SubscriptionEventDetail
} from "../other/model/subscriptionEventDetail";
import {Observable, Subscription} from "rxjs";
import {DatePipe} from "@angular/common";
import {RangeType} from "../other/model/organiserCategory";
import {selectMany} from "../other/utils/select-many";
import {GenderObject, GenderService} from "../other/services/gender.service";
import {StepperSelectionEvent} from "@angular/cdk/stepper";
import * as moment from "moment";
import {distinctList} from "../other/utils/filter";
import {OrganiserExtraField} from "../other/model/organiserExtraField";
import {ExtraFieldData} from "../other/model/extraFieldData";
import {AlertService} from "../other/services/alter.service";
import {TranslateService} from "@ngx-translate/core";
import {NgForm} from "@angular/forms";
import {PaymentMethod} from "../other/model/organiserEvent";
import {environment} from "../../environments/environment";
import {Gender} from "../../../../../org.sneyders.bibchip.web/ClientApp/src/app/model/gender";
import {PaymentService} from "../other/services/payment.service";

@Component({
  selector: 'app-subscribe-event',
  templateUrl: './subscribe-event.component.html',
  styleUrls:['subscribe-event.component.scss'],
  providers:[DatePipe]
})
export class SubscribeEventComponent implements  OnInit, OnDestroy {

  public event: SubscriptionEventDetail;
  private eventId: string;
  public participant: any;
  private subscriptions: Subscription[];

  public genders: Observable<GenderObject[]>;
  public allCompetitions: SubscriptionCompetition[];
  public allCategories: SubscriptionCategory[];
  public competitions: SubscriptionCompetition[];
  public categories: SubscriptionCategory[];

  public selectedCompetition: SubscriptionCompetition;
  @ViewChild('stepper')
  public formStepper: MatStepper;

  public extraFieldSteps:  { [name: string] : OrganiserExtraField[]; };
  public stepNames: string[];
  public defaultExtraFields: OrganiserExtraField[];
  public extraFieldData: ExtraFieldData[];

  @ViewChild('basicInfo', { static: true})
  public basicInfoForm: NgForm;
  @ViewChild('addressInfo', { static: true})
  public addressInfoForm: NgForm;
  @ViewChild('competitionCategory', { static: true})
  public competitionCategoryForm: NgForm;
  @ViewChild('extraFieldsForm', { static: true})
  public extraFieldsFormObject: NgForm;

  @ViewChildren('stepForm')
  public stepForms: NgForm[];
  payment: string;

  public paypal: PaymentMethod = PaymentMethod.Paypal;
  public bancontact: PaymentMethod = PaymentMethod.Bancontact;
  public manual: PaymentMethod = PaymentMethod.Manual;

  public bancontactImage: string;
  public bancontactDeeplink: string;

  public error: boolean;
  public isFinished: boolean;
  public paymentMethods: number[];

  constructor(private router: Router, private translateService: TranslateService, private paymentService: PaymentService, private alertService: AlertService, private activatedRoute: ActivatedRoute, private genderService: GenderService, private httpService: BibchipHttpService, public navigationService: NavigationService, private datePipe: DatePipe) {
    this.participant = {};
    this.subscriptions = [];
    this.extraFieldSteps = {};
    this.defaultExtraFields = [];
    this.stepNames = [];
    this.extraFieldData = [];
    this.payment = "";
    this.error = false;
    this.isFinished = false;

    if (!environment.production) {
      this.participant.firstName = 'Dieter';
      this.participant.lastName = 'Sneyders';
      this.participant.email = 'dietersneyders@gmail.com';
      this.participant.gender = Gender.Male;
      this.participant.street = 'Boudewijnstraat';
      this.participant.houseNumber = '53';
      this.participant.postalCode = '2340';
      this.participant.city = 'Beerse';
      this.participant.country = 'Belgium';
      this.participant.birthDate = new Date(1990,10,16);
    }
  }

  isPaymentMethodActive(method: PaymentMethod) : boolean {
    return this.paymentMethods.indexOf(method) >= 0;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  ngOnInit(): void {
    this.subscriptions.push(this.activatedRoute.paramMap.subscribe(params => {
      this.eventId = params.get("id");
      this.httpService.get<SubscriptionEventDetail>('subscriptions/' + this.eventId).subscribe(x => {
        this.event = x;
        this.allCompetitions = x.competitions;
        this.allCategories = selectMany(x.competitions, y => y.categories);
        this.competitions = this.allCompetitions;
        this.categories = this.allCategories;
        this.paymentMethods = this.paymentService.getMethodsFromEnum(this.event.paymentMethod);

        let currentStep = null;
        this.event.extraFields.sort((a,b) => a.position - b.position).forEach(y => {
          let data = new ExtraFieldData();
          data.extraField = y;
          data.participant = this.participant;
          this.extraFieldData.push(data);

          if(!y.startsNewStepInSubscriptionProcess && currentStep == null)
            this.defaultExtraFields.push(y);
          else if(!y.startsNewStepInSubscriptionProcess)
            this.extraFieldSteps[currentStep].push(y);
          else {
            currentStep = y.newStepTitle;
            this.stepNames.push(y.newStepTitle);
            this.extraFieldSteps[currentStep] = [y];
          }
        });

        this.subscriptions.push(this.activatedRoute.data.subscribe(data => {
          this.participant.isPreview = data.isPreview;
          this.navigationService.lastNavigationAction().subscribe(link => {
            if(!link) {
              if(data.isPreview) {
                this.navigationService.addNavigationAction('/organiser/events/' + this.eventId + '/dashboard', x.name + ' (' + this.datePipe.transform(x.date, 'dd-MM-yyyy') + ') !!PREVIEW!!');

              } else {
                this.navigationService.addNavigationAction('/subscribe', x.name + ' (' + this.datePipe.transform(x.date, 'dd-MM-yyyy') + ')');
              }
            }
          }).unsubscribe();
          if(params.get('participantId')) {
            this.loadExistingParticipant(params.get('participantId'), data.isPreview);
          }
        }));
      }, error => {
        this.alertService.error("AnErrorOccured");
        this.error = true;
      });
    }));
    this.genders = this.genderService.getGenderList(false, false);
  }

  switchStep($event: StepperSelectionEvent) {
    if(!this.participant.isPreview && (this.event.paymentMethod && $event.selectedIndex == this.formStepper.steps.length-1 && !this.payment && !this.participant.wizardFinished)) {
      setTimeout(() => {
        this.formStepper.selectedIndex = $event.previouslySelectedIndex;
      }, 20);
    }
  }

  getCorrectExtraFieldData(extraField: OrganiserExtraField) : ExtraFieldData {
    return this.extraFieldData.filter(x => x.extraField == extraField)[0];
  }

  private checkRanges(x: SubscriptionCategory) {
    const age = this.getAge();
    return x.ranges.length == 0 || x.ranges.filter(y => y.type == RangeType.Age && y.start <= age && y.end >= age).length > 0;
  }

  private getAge(): number {
    return moment().diff(this.participant.birthDate, 'years');
  }

  filterCompetitionsAndCategories() {
    let categories = this.allCategories.filter(x => (x.gender == this.participant.gender || x.gender > 2) && this.checkRanges(x));
    let competitionIds =  distinctList(categories.map(y => y.competition), y => y.id).map(x => x.id);
    this.competitions = this.allCompetitions.filter(x => competitionIds.indexOf(x.id) >= 0);
    if(this.competitions.length == 1) {
      this.selectedCompetition = this.competitions[0];
    }
    this.categories = categories.filter(x => !this.selectedCompetition ||  x.competition.id == this.selectedCompetition.id);

    if(this.participant.category && this.categories.indexOf(this.participant.category) == -1)
      this.participant.category = null;

    if(this.categories.length == 1) {
      this.participant.category = this.categories[0];
    }
  }

  getDefaultExtraFields(category: any) : OrganiserExtraField[] {
    return this.defaultExtraFields.filter(x => category.extraFields.map(y => y.id).indexOf(x.id) >= 0);
  }

  getStepNames(category: any): string[] {
    let names = [];
    this.stepNames.forEach(x => {
      if(this.getStepExtraFields(x, category).length > 0)
        names.push(x);
    });
    return names;
  }

  getStepExtraFields(stepName: string, category: any): OrganiserExtraField[] {
    return this.extraFieldSteps[stepName].filter(x => category.extraFields.map(y => y.id).indexOf(x.id) >= 0);
  }

  save(paymentMethod?: PaymentMethod) {
    let part = JSON.parse(JSON.stringify(this.participant));
    part.categoryId = part.category.id;
    part.category = undefined;
    part.paymentMethod = paymentMethod;
    part.extraFieldData = this.extraFieldData.filter(x => x.value !== undefined).map(x =>  { return { extraFieldId:x.extraField.id, value:x.value }});
    console.log(part);
    if(this.participant.id) {
      part.connectionId = this.paymentService.getConnectionId();
      this.httpService.put('subscriptions/' + this.eventId, part).subscribe(x => {
        this.parseSaveParticipant(paymentMethod, x);
      });
    } else {
      part.connectionId = this.paymentService.getConnectionId();
      this.httpService.post('subscriptions/' + this.eventId, part).subscribe(x => {
        this.parseSaveParticipant(paymentMethod, x);
      });
    }
  }

  parseSaveParticipant(paymentMethod, x) {
    if (!paymentMethod) {
      this.finished();
    } else if (paymentMethod == this.paypal) {
      document.location = (<any>x).paypalLink;
    }else if (paymentMethod == this.manual){
      this.participant.id = x.id;
      setTimeout(() => {
        this.navigateToPage(this.formStepper.steps.length - 1);
      }, 20);
    } else if(paymentMethod == this.bancontact) {
      this.participant.id = x.participantId;
      this.bancontactImage = x.qrCode;
      this.bancontactDeeplink = x.deeplink;
      this.subscriptions.push(this.paymentService.paymentDone().subscribe(x => {
        if (x && x.successful && x.participantId == this.participant.id) {
          this.payment = "success";
        } else if (x && !x.successful) {
          this.payment = "fail";
        }
      }));
    } else {
      alert('not supported!');
    }
  }

  finished() : void {
    const message = this.translateService.instant("SuccessfulSubscribedForEvent") + " '" + this.event.name + "' (" + this.datePipe.transform(this.event.date, 'dd-MM-yyyy') + ")";
    this.alertService.success(message);
    this.isFinished = true;
  }

  finishWizard() : void {
    if(!this.participant.wizardFinished) {
      this.httpService.put('subscriptions/' + this.eventId + "/" + this.participant.id, null).subscribe(x => {
        this.finished();
      });
    } else {
      this.finished();
    }
  }

  checkExtraFieldValue(ef: OrganiserExtraField) {
    let data = this.extraFieldData.filter(x => x.extraField.id == ef.onlyVisibleWhenThisIsFilledIn.id)[0];
    return data.value;
  }

  private loadExistingParticipant(participantId: string, isPreview: boolean) {
    this.httpService.get<any>('subscriptions/' + this.eventId + "/" + participantId).subscribe(x => {
      this.participant = x;
      this.participant.isPreview = isPreview;
      this.filterCompetitionsAndCategories();
      this.participant.category = this.categories.filter(y => y.id == x.categoryId)[0];
      this.selectedCompetition = this.allCompetitions.filter(y => y.id == this.participant.category.competitionId)[0];
      this.extraFieldData.forEach(y => {
        let matchingField = x.extraFieldData.filter(z => z.extraFieldId == y.extraField.id);
        if(matchingField.length > 0) {
          console.log("extrafield value found!");
          console.log("extrafield", matchingField);
          y.value = matchingField[0].value;
        }
      });

      this.subscriptions.push(this.activatedRoute.queryParams.subscribe(p => {
        if(p['payment']) {
          this.payment = p['payment'];
          setTimeout(() => {
            let indexSubstraction = x.wizardFinished ? 1 : 2;
            let page = this.formStepper.steps.length -indexSubstraction;
            this.navigateToPage(page);
          }, 20);
        } else {
          setTimeout(() => {
            this.navigateToPage(this.formStepper.steps.length - 1);
          }, 20);
        }
      }));
    });
  }

  navigateToPage(index: number) {
    while(index != this.formStepper.selectedIndex) {
      this.formStepper.selected.editable = false;
      this.formStepper.next();
    }
  }
}
