import {Component, OnInit, Inject, Input} from '@angular/core';
import {OrganiserExtraField} from "../../model/organiserExtraField";
import {ExtraFieldData} from "../../model/extraFieldData";
import { NG_VALUE_ACCESSOR, NgForm} from "@angular/forms";
import {ValueAccessorBase} from "./valueAccessorBase";

@Component({
  selector: 'app-extra-field',
  templateUrl: './extra-field.component.html',
  styleUrls: ['./extra-field.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: ExtraFieldComponent, multi: true}
  ]
})
export class ExtraFieldComponent extends ValueAccessorBase<any> implements OnInit {

  @Input()
  public extraField: OrganiserExtraField;


  constructor() {
    super()
  }

  ngOnInit() {
  }
}
