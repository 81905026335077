import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {BibchipHttpService} from "../../../other/services/bibchip.http.service";
import {UserService} from "../../../other/services/user.service";
import {MatDialogRef} from "@angular/material/dialog";
import {InvoiceInfo, OrganiserEvent, PaymentMethod} from "../../../other/model/organiserEvent";

@Component({
  selector: 'app-organiser-manage-invoice-info',
  templateUrl: './manage-invoice-info.component.html',
  styleUrls:['./manage-invoice-info.component.scss']
})
export class OrganiserManageInvoiceInfoComponent implements OnInit {

  public event: OrganiserEvent;

  constructor(public dialogRef: MatDialogRef<OrganiserManageInvoiceInfoComponent>, private httpService: BibchipHttpService, private userService: UserService, private router: Router) {
    this.event = new OrganiserEvent();
    this.event.invoiceInfo = new InvoiceInfo();
  }

  ngOnInit(): void {

  }

  loadEvent(e: OrganiserEvent): void {
    this.event = e;
  }

  save() : void {
      this.httpService.put("organiser/events/" + this.event.id + "/invoice-info", this.event.invoiceInfo).subscribe(x => {
        this.dialogRef.close();
      });
  }
}
