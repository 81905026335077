import {Component, OnInit} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {OrganiserEvent} from "../../other/model/organiserEvent";
import {BibchipHttpService} from "../../other/services/bibchip.http.service";
import {UserService} from "../../other/services/user.service";
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from "@angular/router";
import {NavigationService} from "../../other/services/navigation.service";
import {DatePipe} from "@angular/common";
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {OrganiserManageEventComponent} from "./events/manage-event.component";
import {OrganiserManageInvoiceInfoComponent} from "./invoice/manage-invoice-info.component";
import {OrganiserManageEmailConfirmationComponent} from "./confirmation-email/email-confirmation.component";
import {OrganiserProfitsComponent} from "./profit/profits.component";
import {OrganiserPublishComponent} from "./publish/publish.component";

@Component({
  selector: 'app-organiser-event-dashboard',
  templateUrl: './event-dashboard.component.html',
  styleUrls:['./event-dashboard.component.scss'],
  providers:[DatePipe]
})
export class OrganiserEventDashboardComponent implements OnInit {

  public event: OrganiserEvent;
  private subscriptions: Subscription[];
  public isPublished: boolean;

  constructor(public dialog: MatDialog, private translateService: TranslateService, private activatedRoute: ActivatedRoute, private httpService: BibchipHttpService, private userService: UserService, private router: Router, public navigationService: NavigationService, public datePipe: DatePipe) {
    this.subscriptions = [];
  }

  ngOnInit(): void {
    this.subscriptions.push(this.activatedRoute.paramMap.subscribe(x => {
      let evId = x.get('id');
      this.httpService.get<OrganiserEvent>('organiser/events/' + evId + '?' + new Date().getTime()).subscribe(y => {
        this.event = y;
        this.isPublished = y.isLive && y.subscriptionStart != null;
        this.navigationService.lastNavigationAction().subscribe(link => {
          if(!link) {
            this.navigationService.addNavigationAction('/organiser/events', y.name + ' (' + this.datePipe.transform(y.date, 'dd-MM-yyyy') + ')');
          }
        }).unsubscribe();
      });
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  getNavigationText(text: string): string {
    return this.event.name + ' (' + this.translateService.instant(text) + ')';
  }

  edit() {
    const dialogRef = this.dialog.open(OrganiserManageEventComponent);
    dialogRef.componentInstance.loadEvent(this.event);
    dialogRef.afterClosed().subscribe(x => {  });
  }

  confirmationEmail() {
    const dialogRef = this.dialog.open(OrganiserManageEmailConfirmationComponent,  {
      width: '80%'
    });
    dialogRef.componentInstance.loadEvent(this.event);
    dialogRef.afterClosed().subscribe(x => {  });
  }

  costsAndProfits() : void {
    const dialogRef = this.dialog.open(OrganiserProfitsComponent);
    dialogRef.componentInstance.loadEvent(this.event);
    dialogRef.afterClosed().subscribe(x => {  });
  }

  invoiceInfo() {
    const dialogRef = this.dialog.open(OrganiserManageInvoiceInfoComponent);
    dialogRef.componentInstance.loadEvent(this.event);
    dialogRef.afterClosed().subscribe(x => {  });
  }

  publish() {
    const dialogRef = this.dialog.open(OrganiserPublishComponent);
    dialogRef.componentInstance.loadEvent(this.event);
    dialogRef.afterClosed().subscribe(x => { this.ngOnInit() });
  }
}
