<h2 mat-dialog-title>
  <span *ngIf="!event.id || event.id == 0" translate>New</span>
  <span *ngIf="event.id && event.id != 0" translate>Edit</span>
</h2>
<mat-dialog-content class="mat-typography">
  <form class="example-form" #eventForm="ngForm">
    <mat-form-field class="example-full-width">
      <input matInput required placeholder="{{ 'Name' | translate }}" name="name" [(ngModel)]="event.name">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput [matDatepicker]="eventDatePicker" required placeholder="{{ 'Date' | translate }}" name="date" [(ngModel)]="event.date">
      <mat-datepicker-toggle class="big-icon" [for]="eventDatePicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #eventDatePicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput required placeholder="{{ 'OrganiserEmail' | translate }}" name="organiserEmail" [(ngModel)]="event.organiserEmail">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput type="number" placeholder="{{ 'MaxNumberOfParticipants' | translate }}" name="maxParticipants" [(ngModel)]="event.maxNumberOfParticipants">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput placeholder="{{ 'OrganiserWebsite' | translate }}" name="organiserWebsite" [(ngModel)]="event.organiserWebsite">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <mat-label><span translate>PaymentMethod</span></mat-label>
      <mat-select required [(ngModel)]="selectedMethods" name="paymentmethod" (selectionChange)="paymentMethodChanged()" multiple>
        <mat-option *ngFor="let method of paymentMethods" [value]="method.id">
          {{method.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- <div class="example-full-width">
       <mat-checkbox  name="isLive" [(ngModel)]="event.isLive">
         {{ 'IsLive' | translate }}
       </mat-checkbox>
     </div>
    <div class="example-full-width">
      <mat-checkbox class="example-full-width"  name="isPubliclyVisible" [(ngModel)]="event.isPresubscriptionPublic">
        {{ 'IsPubliclyVisible' | translate }}
      </mat-checkbox>
    </div>-->

    <mat-form-field class="example-full-width">
      <input matInput [matDatepicker]="subscriptionEndDatePicker" placeholder="{{ 'SubscriptionEnd' | translate }}" name="subscriptionEnd" [(ngModel)]="event.subscriptionEnd">
      <mat-datepicker-toggle class="big-icon" [for]="subscriptionEndDatePicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #subscriptionEndDatePicker></mat-datepicker>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close><span translate>Cancel</span></button>
  <button mat-button [disabled]="!eventForm.valid" (click)="add()" cdkFocusInitial>
    <span *ngIf="!event.id || event.id == 0" translate>Add</span>
    <span *ngIf="event.id && event.id != 0" translate>Save</span>
  </button>
</mat-dialog-actions>
