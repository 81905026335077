import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import {UserService} from "../services/user.service";

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(public userService: UserService, public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data.expectedRole;
    // decode the token to get its payload
    let user = this.userService.currentUser();
    if (!user || !this.userService.isInRole(expectedRole)) {
      this.router.navigate(['signin']);
      return false;
    }
    return true;
  }
}
