export class ValidationError {
  errors: ErrorItem[];
  title: string;
  status: number;
  traceId: string;
}

export class ErrorItem {
  name: string;
  message: string;
}
