import {Injectable} from "@angular/core";
import {Event as NavigationEvent, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {filter, map} from "rxjs/operators";
import {BehaviorSubject, Observable, Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
  })
export class NavigationService {

  private backActions: BehaviorSubject<LinkInfo[]>;
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  private fullScreenRequestedSubject: Subject<boolean>;

  constructor(private router: Router) {
    this.fullScreenRequestedSubject = new BehaviorSubject(false);
    this.backActions = new BehaviorSubject([]);
    this.currentUrl = this.router.url;
    router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          if(this.backActions.value.filter(x => x.url ==  event.url).length > 0)
            this.removeFromActionList(this.backActions.value.filter(x => x.url ==  event.url)[0]);
        }
      });
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.fullScreenRequestedSubject.next(false);
      }
    });
  }

  requestFullScreen() {
    this.fullScreenRequestedSubject.next(true);
  }

  fullScreenRequested() : Observable<boolean> {
    return this.fullScreenRequestedSubject.asObservable();
  }

  addNavigationAction(path: string, headerText?: string): void {
    this.backActions.next(this.backActions.value.concat({ url: path, headerText: headerText }));
  }

  addPreviousPage(): void {
    this.addNavigationAction(this.previousUrl);
  }

  addCurrentPage(headerText?: string): void {
    this.addNavigationAction(this.currentUrl, headerText);
  }

  lastNavigationAction() : Observable<LinkInfo> {
      return this.backActions.asObservable().pipe(map(x => x.length > 0 ?  x[x.length-1] : null));
  }

  navigateBack() : Promise<boolean> {
    return this.navigateBackToPath(this.backActions.value[this.backActions.value.length-1]);
  }

  navigateBackToPath(info: LinkInfo) : Promise<boolean> {
    let url = info.url;
    this.removeFromActionList(info);
    return this.router.navigateByUrl(url);
  }

  private removeFromActionList(info: LinkInfo) {
    let items = this.backActions.value;
    items.splice(items.indexOf(info), 1);
    this.backActions.next(items);
  }

  clearNavigation() {
    this.backActions.next([]);
  }
}

class LinkInfo {
  url: string;
  headerText?: string;
}
