export class OrganiserEvent {
  id: number;
  name: string;
  date: Date;
  isLive: boolean;
  organiserEmail: string;
  organiserWebsite: string;
  isPresubscriptionPublic: boolean;
  subscriptionEnd?: Date;
  maxNumberOfParticipants?: number;
  paymentMethod?: PaymentMethod;
  invoiceInfo?: InvoiceInfo;
  emailTemplateForSuccessfulSubscription?: string;
  subscriptionStart?: Date;
}

export class InvoiceInfo {
  companyName: string;
  contactName: string;
  streetAndNumber: string;
  postalCode: string;
  city: string;
  email: string;
}

export enum PaymentMethod {
  Paypal = 1,
  Bancontact= 2,
  PaypalAndBancontact = 3,
  Manual = 1000,
  ManualAndPaypal = 1001,
  ManualAndBancontact = 1002,
  ManualPaypalAndBancontact = 1003,
}
