import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {BibchipHttpService} from "../../../other/services/bibchip.http.service";
import {UserService} from "../../../other/services/user.service";
import {MatDialogRef} from "@angular/material/dialog";
import {InvoiceInfo, OrganiserEvent, PaymentMethod} from "../../../other/model/organiserEvent";
import {EditorComponent} from "@tinymce/tinymce-angular";
import {EditorService} from "../../../other/services/editor.service";
import {OrganiserProfit} from "../../../other/model/organiser-profit";
import {TranslateService} from "@ngx-translate/core";
import {PaymentService} from "../../../other/services/payment.service";

@Component({
  selector: 'app-organiser-profits',
  templateUrl: './profits.component.html',
  styleUrls:['./profits.component.scss']
})
export class OrganiserProfitsComponent implements OnInit {

  public event: OrganiserEvent;
  public profit: OrganiserProfit;

  constructor(private translateService: TranslateService, private paymentService: PaymentService, public dialogRef: MatDialogRef<OrganiserProfitsComponent>, private httpService: BibchipHttpService) {
    this.event = new OrganiserEvent();
  }

  ngOnInit(): void {

  }

  loadEvent(e: OrganiserEvent): void {
    this.event = e;
    this.loadProfits();
  }

  loadProfits(): void {
    this.httpService.get<OrganiserProfit>('profits/' + this.event.id).subscribe(x => {
     this.profit = x;
    });
  }

  getPaymentMethodString(paymentMethod: PaymentMethod): string {
    return this.paymentService.getPaymentMethods(true).filter(x => x.id == paymentMethod)[0].name;
  }
}
