<h2 mat-dialog-title>
  <span *ngIf="!category.id || category.id == 0" translate>New</span>
  <span *ngIf="category.id && category.id != 0" translate>Edit</span>
</h2>
<mat-dialog-content class="mat-typography">
  <form class="example-form" #catForm="ngForm">
    <mat-form-field class="example-full-width">
      <input matInput required placeholder="{{ 'Name' | translate }}" name="name" [(ngModel)]="category.name">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <mat-label><span translate>Competition</span></mat-label>
      <mat-select [(ngModel)]="category.organiserCompetition" name="competition" (selectionChange)="updateId()" required>
        <mat-option *ngFor="let comp of competitions" [value]="comp">
          {{comp.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <mat-label><span translate>Gender</span></mat-label>
      <mat-select [(ngModel)]="category.gender" name="gender" required>
        <mat-option *ngFor="let gender of genders | async" [value]="gender.value">
          {{gender.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput type="number" placeholder="{{ 'Price' | translate }}" name="price" [(ngModel)]="category.price">
    </mat-form-field>
    <h4 translate>AgeRanges</h4>
    <ng-container *ngFor="let range of category.ranges | filter: ageRangeFilter; let i = index">
      <mat-form-field class="example-third-width">
        <input matInput type="number" required placeholder="{{ 'Start' | translate }}" name="age-start-{{range.addedOn ? range.addedOn : range.id}}" [(ngModel)]="range.start">
      </mat-form-field>
      <mat-form-field class="example-third-width spacing">
        <input matInput type="number" required placeholder="{{ 'End' | translate }}" name="age-end-{{range.addedOn ? range.addedOn : range.id}}" [(ngModel)]="range.end">
      </mat-form-field>
      <div class="example-third-width delete-range-button">
        <button mat-mini-fab color="warn" (click)="removeRange(range)">
          <mat-icon>
            delete
          </mat-icon>
        </button>
      </div>
    </ng-container>
    <span *ngIf="!category.ranges || category.ranges.length == 0" translate>NoAgeRangesYet<br/></span>
    <button (click)="addAgeRange()" mat-mini-fab color="accent">
      <mat-icon>add</mat-icon>
    </button>
    <mat-form-field class="example-full-width">
      <input matInput type="number" placeholder="{{ 'MaxNumberOfParticipants' | translate }}" name="maxParticipants" [(ngModel)]="category.maxNumberOfParticipants">
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close><span translate>Cancel</span></button>
  <button mat-button [disabled]="!catForm.valid" (click)="add()" cdkFocusInitial>
    <span *ngIf="!category.id || category.id == 0" translate>Add</span>
    <span *ngIf="category.id && category.id != 0" translate>Save</span>
  </button>
</mat-dialog-actions>
