import { ContentComponent } from './other/shared/content/content.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import {FaConfig, FaIconLibrary, FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {fas} from "@fortawesome/free-solid-svg-icons";
import { far } from '@fortawesome/free-regular-svg-icons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorIntl } from "@angular/material/paginator";
import {ResultsComponent} from "./results/results.component";
import {SubscribeComponent} from "./presubscriptions/subscribe.component";
import {SignInComponent} from "./administration/sign-in.component";
import {BibchipHttpService} from "./other/services/bibchip.http.service";
import {AuthInterceptor} from "./other/interceptors/auth.interceptor";
import {UsersOverviewComponent} from "./administration/user-management/users-overview.component";
import {ManageUserComponent} from "./administration/user-management/manage-user.component";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {NgxSpinnerModule} from "ngx-spinner";
import {HttpErrorInterceptor} from "./other/interceptors/error.http.interceptor";
import {LoaderHttpInterceptor} from "./other/interceptors/loader.http.interceptor";
import {ErrorService} from "./other/services/error.service";
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {PaginatorI18n} from "./other/providers/paginator.i18n";
import {ConfirmDialogComponent} from "./other/shared/confirm-dialog/confirm-dialog.component";
import {MaterialModule} from "./other/material.module";
import {FieldErrorShowerComponent} from "./other/shared/error/field-error-shower.component";
import {ErrorShowerComponent} from "./other/shared/error/error-shower.component";
import {RoleGuard} from "./other/guards/role.guard";
import {Roles} from "./other/model/roles";
import {ResultEventComponent} from "./results/result-event.component";
import {FilterPipe} from "./other/pipes/filter.pipe";
import {ResultCompetitionComponent} from "./results/result-competition.component";
import {AlternateNavMenuComponent} from "./nav-menu/alternate-menu.component";
import {OrganiserManageEventComponent} from "./administration/organiser/events/manage-event.component";
import {OrganiserEventOverviewComponent} from "./administration/organiser/events/event-overview.component";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from "@angular/material-moment-adapter";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {OrganiserCompetitionOverviewComponent} from "./administration/organiser/competitions/competition-overview.component";
import {DatePipe} from "@angular/common";
import {OrganiserManageCompetitionComponent} from "./administration/organiser/competitions/manage-competition.component";
import {OrganiserEventDashboardComponent} from "./administration/organiser/event-dashboard.component";
import {OrganiserCategoryOverviewComponent} from "./administration/organiser/categories/category-overview.component";
import {OrganiserManageCategoryComponent} from "./administration/organiser/categories/manage-category.component";
import {GenderTextPipe} from "./other/pipes/gender-text.pipe";
import {OrganiserExtraFieldOverviewComponent} from "./administration/organiser/extra-fields/extra-field-overview.component";
import {OrganiserManageExtraFieldComponent} from "./administration/organiser/extra-fields/manage-extra-field.component";
import {OrganiserCoupleExtraFieldComponent} from "./administration/organiser/extra-fields/couple-extra-field.component";
import {AsEnumListPipe} from "./other/pipes/asEnumList.pipe";
import {SubscribeEventComponent} from "./presubscriptions/subscribe-event.component";
import {ExtraFieldComponent} from "./other/shared/extra-field/extra-field.component";
import {AlertService} from "./other/services/alter.service";
import {AlertComponent} from "./other/shared/alert/alert.component";
import {NgxPayPalModule} from "ngx-paypal";
import {OrganiserParticipantOverviewComponent} from "./administration/organiser/participants/participant-overview.component";
import {fab} from "@fortawesome/free-brands-svg-icons";
import {OrganiserManageInvoiceInfoComponent} from "./administration/organiser/invoice/manage-invoice-info.component";
import {OrganiserManageEmailConfirmationComponent} from "./administration/organiser/confirmation-email/email-confirmation.component";
import {EditorModule} from "@tinymce/tinymce-angular";
import {OrganiserProfitsComponent} from "./administration/organiser/profit/profits.component";
import {OrganiserPublishComponent} from "./administration/organiser/publish/publish.component";
// AoT requires an exported function for factories
/*export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}*/

//create our cost var with the information about the format that we want
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  isUtc: true,
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD-MM-YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cb=' + new Date().getTime());
}

@NgModule({
    declarations: [
        AppComponent,
        AlternateNavMenuComponent,
        HomeComponent,
        ResultsComponent,
        SubscribeComponent,
        SubscribeEventComponent,
        SignInComponent,
        ConfirmDialogComponent,
        UsersOverviewComponent,
        ManageUserComponent,
        FieldErrorShowerComponent,
        ErrorShowerComponent,
        AlertComponent,
        ResultEventComponent,
        ResultCompetitionComponent,
        ExtraFieldComponent,
        OrganiserManageEventComponent,
        OrganiserEventOverviewComponent,
        OrganiserCompetitionOverviewComponent,
        OrganiserManageCompetitionComponent,
        OrganiserCategoryOverviewComponent,
        OrganiserManageCategoryComponent,
        OrganiserExtraFieldOverviewComponent,
        OrganiserManageExtraFieldComponent,
        OrganiserCoupleExtraFieldComponent,
        OrganiserEventDashboardComponent,
        OrganiserParticipantOverviewComponent,
        OrganiserManageInvoiceInfoComponent,
        OrganiserManageEmailConfirmationComponent,
        OrganiserProfitsComponent,
        OrganiserPublishComponent,
        FilterPipe,
        GenderTextPipe,
        AsEnumListPipe,
        ContentComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FontAwesomeModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgxSpinnerModule,
        EditorModule,
        FormsModule,
        NgxPayPalModule,
        RouterModule.forRoot([
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', component: HomeComponent },
            { path: 'results', component: ResultsComponent },
            { path: 'results/:id', component: ResultEventComponent },
            { path: 'results/:id/competition/:competitionId', component: ResultCompetitionComponent },
            { path: 'subscribe', component: SubscribeComponent },
            { path: 'subscribe/:id', component: SubscribeEventComponent },
            { path: 'subscribe/:id/:participantId', component: SubscribeEventComponent },
            { path: 'signin', component: SignInComponent },
            //admin
            { path: 'admin/users', component: UsersOverviewComponent, canActivate: [RoleGuard], data: { expectedRole: Roles.Admin } },
            //organiser
            { path: 'organiser/events', component: OrganiserEventOverviewComponent, canActivate: [RoleGuard], data: { expectedRole: Roles.EventOrganiser } },
            { path: 'organiser/events/:id/dashboard', component: OrganiserEventDashboardComponent, canActivate: [RoleGuard], data: { expectedRole: Roles.EventOrganiser } },
            { path: 'organiser/events/:id/competitions', component: OrganiserCompetitionOverviewComponent, canActivate: [RoleGuard], data: { expectedRole: Roles.EventOrganiser } },
            { path: 'organiser/events/:id/categories', component: OrganiserCategoryOverviewComponent, canActivate: [RoleGuard], data: { expectedRole: Roles.EventOrganiser } },
            { path: 'organiser/events/:id/extra-fields', component: OrganiserExtraFieldOverviewComponent, canActivate: [RoleGuard], data: { expectedRole: Roles.EventOrganiser } },
            { path: 'organiser/events/:id/participants', component: OrganiserParticipantOverviewComponent, canActivate: [RoleGuard], data: { expectedRole: Roles.EventOrganiser } },
            { path: 'organiser/preview/subscribe/:id', component: SubscribeEventComponent, canActivate: [RoleGuard], data: { isPreview: true, expectedRole: Roles.EventOrganiser } },
            { path: 'organiser/preview/subscribe/:id/:participantId', component: SubscribeEventComponent, canActivate: [RoleGuard], data: { isPreview: true, expectedRole: Roles.EventOrganiser } },
        ]),
        BrowserAnimationsModule,
        MaterialModule
    ],
    providers: [RoleGuard, BibchipHttpService, ErrorService, AlertService,
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { strict: false } },
        { provide: MAT_DATE_LOCALE, "useValue": 'nl-BE' },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }, {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        }, {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderHttpInterceptor,
            multi: true
        }, {
            provide: MatPaginatorIntl, deps: [TranslateService],
            useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl()
        }],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary, translate: TranslateService, faConfig: FaConfig) {
    library.addIconPacks(fas, far, fab);
    faConfig.defaultPrefix = "fas";
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    let language = localStorage.getItem('LANGUAGE');
    if(language == null)
      localStorage.setItem('LANGUAGE', 'be');
    language = localStorage.getItem('LANGUAGE');
    translate.use(language);
  }
}
