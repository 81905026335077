import {Component, Input, OnInit} from '@angular/core';
import {ResultCompetitionSummary, ResultEventSummary} from "../other/model/resultEventSummary";
import {BibchipHttpService} from "../other/services/bibchip.http.service";
import {NavigationService} from "../other/services/navigation.service";

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls:['results.component.scss']
})
export class ResultsComponent implements OnInit {

  public events: ResultEventSummary[];

  @Input()
  numberOfResultsToShow: number | null = null;

  @Input()
  public componentPadding: boolean = true;

  constructor(private httpClient: BibchipHttpService, public navigationService: NavigationService) {

  }

  openEvent(ev: ResultEventSummary): void {
    alert(ev.name);
  }

  ngOnInit(): void {
    let query = "";
    if(this.numberOfResultsToShow != null)
      query = "?take=" + this.numberOfResultsToShow;

    this.httpClient.get<ResultEventSummary[]>('results/summary' + query).subscribe(x => {
      this.events = x;
    });
  }

  shouldHaveParticipants() {
    return function (comp: ResultCompetitionSummary) {
      return comp.numberOfParticipants > 0;
    };
  }
}
