<h3 class="title" translate>ExtraFields</h3>
<div class="table-container" *ngIf="extraFields && extraFields.data.length > 0">
  <mat-table [dataSource]="extraFields">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef translate>Name</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label" translate>Name</span>
        {{element.name}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="isRequired">
      <mat-header-cell *matHeaderCellDef translate>Required</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label" translate>Required</span>
        <mat-checkbox color="accent" [checked]="element.isRequired" disabled="true">
        </mat-checkbox>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="dependentOn">
      <mat-header-cell *matHeaderCellDef translate>DependentOn</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label" translate>DependentOn</span>
        {{element.onlyVisibleWhenThisIsFilledIn?.name}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell class="actions" *matHeaderCellDef translate>Actions</mat-header-cell>
      <mat-cell class="actions" *matCellDef="let element; let index = index; let first = first;let last=last">
        <span class="mobile-label" translate>Actions</span>
        <button matTooltip="{{ 'MoveDown' | translate }}" [disabled]="canNotMove(element, last)" mat-icon-button (click)="moveDown(element)">
          <mat-icon>arrow_downward</mat-icon>
        </button>
        <button matTooltip="{{ 'MoveUp' | translate }}" [disabled]="canNotMove(element, first)" mat-icon-button (click)="moveUp(element)">
          <mat-icon>arrow_upward</mat-icon>
        </button>
        <button matTooltip="{{ 'Edit' | translate }}" mat-icon-button (click)="edit(element)">
          <mat-icon>edit</mat-icon>
        </button>
        <button matTooltip="{{ 'ConfigureWhereToShow' | translate }}" mat-icon-button (click)="couple(element)">
          <mat-icon>link</mat-icon>
        </button>
        <button  matTooltip="{{ 'Delete' | translate }}" mat-icon-button (click)="deleteConfirmation(element)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
<div class="no-items" *ngIf="!extraFields || extraFields.data.length == 0">
  <span translate>YouDontHaveCreatedAnyExtraFieldsYet</span>
</div>
<!--
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
-->

<button class="add-item" mat-mini-fab color="accent" (click)="add()">
  <mat-icon>add</mat-icon>
</button>
