<h2 mat-dialog-title>
  <span *ngIf="!competition.id || competition.id == 0" translate>New</span>
  <span *ngIf="competition.id && competition.id != 0" translate>Edit</span>
</h2>
<mat-dialog-content class="mat-typography">
  <form class="example-form" #compForm="ngForm">
    <mat-form-field class="example-full-width">
      <input matInput required placeholder="{{ 'Name' | translate }}" name="name" [(ngModel)]="competition.name">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput required placeholder="{{ 'Distance' | translate }}" name="distance" [(ngModel)]="competition.durationOrDistance">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput type="number" placeholder="{{ 'Price' | translate }}" name="price" [(ngModel)]="competition.price" required>
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput type="number" placeholder="{{ 'MaxNumberOfParticipants' | translate }}" name="maxParticipants" [(ngModel)]="competition.maxNumberOfParticipants">
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close><span translate>Cancel</span></button>
  <button mat-button [disabled]="!compForm.valid" (click)="add()" cdkFocusInitial>
    <span *ngIf="!competition.id || competition.id == 0" translate>Add</span>
    <span *ngIf="competition.id && competition.id != 0" translate>Save</span>
  </button>
</mat-dialog-actions>
