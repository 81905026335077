<div class="login-wrapper">
  <mat-card class="box signin-card" *ngIf="!(signedIn | async)">
    <mat-card-header>
      <mat-card-title translate>SignIn</mat-card-title>
    </mat-card-header>
    <app-error-shower></app-error-shower>
    <form class="example-form" #f="ngForm">
      <mat-card-content>
        <mat-form-field class="example-full-width">
          <mat-label translate>Email</mat-label>
          <input matInput [(ngModel)]="email" name="username" placeholder="{{ 'Email' | translate }}" required email>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label translate>Password</mat-label>
          <input [(ngModel)]="password" matInput type="password" name="password" placeholder="{{ 'Password' | translate }}" required>
        </mat-form-field>
      </mat-card-content>
      <button mat-raised-button [disabled]="!f.valid" color="primary" (click)="login()" class="btn-block"><span translate>SignIn</span></button>
    </form>
  </mat-card>
  <mat-card class="box signin-card" *ngIf="signedIn | async">
    <mat-card-content>
      {{ (user | async)?.email }}
    </mat-card-content>
    <button mat-raised-button color="primary" (click)="logout()" class="btn-block"><span translate>SignOut</span></button>
  </mat-card>
</div>
