import {AfterViewInit, Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import {BibchipHttpService} from "../other/services/bibchip.http.service";
import {UserService} from "../other/services/user.service";
import {User} from "../other/model/user";
import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {NavigationService} from "../other/services/navigation.service";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls:['./sign-in.component.scss']
})
export class SignInComponent implements AfterViewInit {
  public email: string;
  public password: string;

  public user: Observable<User>;
  public signedIn: Observable<boolean>;

  constructor(private httpService: BibchipHttpService, private userService: UserService, private router: Router, private navigationService: NavigationService) {

    this.user = this.userService.user();
    this.signedIn = this.userService.isSignedIn();
  }

  login() : void {
      this.httpService.postWithResponse<User>("account/signin", { email: this.email, password: this.password}).subscribe(x => {
        this.userService.signIn(x);
        this.router.navigateByUrl("/");
      });
  }

  logout() : void {
    this.userService.signOut();
  }

  ngAfterViewInit(): void {
    this.navigationService.requestFullScreen();
  }
}
