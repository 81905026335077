<h2 mat-dialog-title>
  <span *ngIf="!user.id || user.id == 0" translate>NewUser</span>
  <span *ngIf="user.id && user.id != 0" translate>EditUser</span>
</h2>
<mat-dialog-content class="mat-typography">
  <form class="example-form" #userForm="ngForm">
    <mat-form-field class="example-full-width">
      <input matInput required placeholder="{{ 'Email' | translate }}" name="email" [(ngModel)]="user.email" email>
      <mat-error>
        <app-field-error-shower [form]="userForm" controlName="email" fieldName="email"></app-field-error-shower>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput required placeholder="{{ 'Lastname' | translate }}" name="lastname" [(ngModel)]="user.lastname">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput required placeholder="{{ 'Firstname' | translate }}" name="firstname" [(ngModel)]="user.firstname">
    </mat-form-field>

    <div *ngFor="let role of roles; let i = index" >
      <mat-checkbox class="example-margin" (change)="onRoleChange($event)" [checked]="user.roles.indexOf(role.name) > -1" [value]="role">{{  'Role.' + role.name | translate}}</mat-checkbox>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close><span translate>Cancel</span></button>
  <button mat-button [disabled]="!userForm.valid || !hasAnyRole" (click)="addUser()" cdkFocusInitial>
    <span *ngIf="!user.id || user.id == 0" translate>Add</span>
    <span *ngIf="user.id && user.id != 0" translate>Save</span>
  </button>
</mat-dialog-actions>
