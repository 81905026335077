<app-content [title]="event?.name">
  <div class="event-details">
    <div class="event-details-title" translate>Date</div><div class="event-details-content"> {{ event?.date | date: 'dd-MM-yyyy' }} </div><br />
    <div class="event-details-title" translate>LastUpdated</div><div  class="event-details-content"> {{ event?.lastSyncedOn | date: 'dd-MM-yyyy HH:mm:ss' }}</div><br />
    <div class="event-details-title" translate>NumberOfParticipants</div><div  class="event-details-content"> {{ event?.numberOfParticipants }}</div>
  </div>
</app-content>
<app-content [title]="'Results' | translate">
  <div class="comp-container">
    <mat-card class="comp-card"  *ngFor="let comp of event?.competitions | filter: shouldHaveResults()">
      <div class="card-fix-container">
          <mat-card-title>{{comp.name}}</mat-card-title>
        <mat-card-content>
        <div *ngFor="let cat of comp.categories">
          <h4 class="cat-title">{{cat}}</h4>
          <ol class="result-list">
            <li *ngFor="let res of comp.results | filter: getFirstCatResults(cat)">
                <div class="name">
                  {{ res.fullname }}
                </div>
                <div class="finish">
                  {{ res.finishTime }}
                </div>
            </li>
          </ol>
        </div>
        </mat-card-content>
        <mat-card-actions class="view-event">
          <a mat-raised-button color="accent" (click)="navigationService.addCurrentPage(this.event.name + ' - ' + this.comp.name)" [routerLink]="'/results/' + event?.id + '/competition/' + comp.id"><span translate>View</span></a>
        </mat-card-actions>
      </div>
    </mat-card>
  </div>

</app-content>

