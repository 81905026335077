<body>
<ngx-spinner bdOpacity=0.9
             bdColor="rgba(33,33,33,0.5)"
             size="medium"
             color="#fff"
             type="ball-spin-clockwise"
             fullScreen="true">
  <p style="color: white"> {{ spinnerText | async }} </p>
</ngx-spinner>
  <app-alternate-nav-menu></app-alternate-nav-menu>
</body>
