import { PipeTransform, Pipe } from '@angular/core';
import {GenderService} from "../services/gender.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Pipe({
  name: 'genderText',
  pure: false
})
export class GenderTextPipe implements PipeTransform {

  constructor(private genderService: GenderService) {

  }

  transform(item: number): Observable<string> {
    return this.genderService.getGenderList(true, true).pipe(map(x => x.filter(y => y.value == item)[0].name));
  }
}
