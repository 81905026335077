import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {flatMap, map} from "rxjs/operators";


@Injectable({providedIn:"root"})
export class GenderService {

  private createBoth: () => Observable<GenderObject>;
  private createUnknown: () => Observable<GenderObject>;

  private createFemale: () => Observable<GenderObject>;
  private createMale: () => Observable<GenderObject>;

  constructor(private  translateService: TranslateService) {
    this.createBoth = () => this.translateService.get('Genders.Both').pipe(map(x => new GenderObject(x, 3)));
    this.createUnknown = () => this.translateService.get('Genders.Unknown').pipe(map(x => new GenderObject(x, 4)));
    this.createFemale = () => this.translateService.get('Genders.Female').pipe(map(x => new GenderObject(x, 1)));
    this.createMale = () => this.translateService.get('Genders.Male').pipe(map(x => new GenderObject(x, 2)));
  }

  getGenderList(includeBoth: boolean, includeUnknown: boolean) : Observable<GenderObject[]> {
    let list = this.createMale().pipe(flatMap(male => this.createFemale().pipe(map(female => [female, male]))));
    if(includeBoth) {
      list = list.pipe(flatMap(genders => this.createBoth().pipe(map(both => { genders.push(both); return genders}))));
    }if(includeUnknown) {
      list = list.pipe(flatMap(genders => this.createUnknown().pipe(map(both => { genders.push(both); return genders}))));
    }

    return list;
  }
}

export class GenderObject {
  constructor(public name: string, public value: number) {

  }
}
export enum GenderEnum {
  Female = 1,
  Male = 2,
  Both = 3,
  Unknown = 4
}
