<h1 mat-dialog-title>
  {{title | translate  }}
</h1>

<div mat-dialog-content>
  <p>{{message | translate : { item: specificItem} }}</p>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onDismiss()"><span translate>No</span></button>
  <button mat-raised-button color="warn" (click)="onConfirm()"> <span translate>Yes</span></button>
</div>
