<h2 mat-dialog-title>
  <span translate>Edit</span>&nbsp;<span translate>InvoiceInfo</span>
</h2>
<mat-dialog-content class="mat-typography">
  <form class="example-form" #eventForm="ngForm">
    <mat-form-field class="example-full-width">
      <input matInput required placeholder="{{ 'CompanyName' | translate }}" name="companyName" [(ngModel)]="event.invoiceInfo.companyName">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput required placeholder="{{ 'ContactName' | translate }}" name="contactName" [(ngModel)]="event.invoiceInfo.contactName">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput required placeholder="{{ 'OrganiserEmail' | translate }}" name="organiserEmail" [(ngModel)]="event.invoiceInfo.email">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput required placeholder="{{ 'StreetAndNumber' | translate }}" name="streetandnumber" [(ngModel)]="event.invoiceInfo.streetAndNumber">
    </mat-form-field> <mat-form-field class="example-full-width">
    <input matInput required placeholder="{{ 'PostalCode' | translate }}" name="postalcode" [(ngModel)]="event.invoiceInfo.postalCode">
  </mat-form-field> <mat-form-field class="example-full-width">
    <input matInput required placeholder="{{ 'City' | translate }}" name="city" [(ngModel)]="event.invoiceInfo.city">
  </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close><span translate>Cancel</span></button>
  <button mat-button [disabled]="!eventForm.valid" (click)="save()" cdkFocusInitial>
    <span  translate>Save</span>
  </button>
</mat-dialog-actions>
