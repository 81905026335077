import {Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {BibchipHttpService} from "../../../other/services/bibchip.http.service";
import {UserService} from "../../../other/services/user.service";
import {MatDialogRef} from "@angular/material/dialog";
import {OrganiserCompetition} from "../../../other/model/organiserCompetition";

@Component({
  selector: 'app-organiser-manage-competition',
  templateUrl: './manage-competition.component.html',
  styleUrls:['./manage-competition.component.scss']
})
export class OrganiserManageCompetitionComponent implements OnInit {

  public competition: OrganiserCompetition;
  private eventId: number;

  constructor(public dialogRef: MatDialogRef<OrganiserManageCompetitionComponent>, private httpService: BibchipHttpService, private userService: UserService, private router: Router) {
    this.competition = new OrganiserCompetition();
  }

  ngOnInit(): void {

  }

  loadCompetition(comp: OrganiserCompetition, eventId: number): void {
    this.competition = comp;
    this.eventId = eventId;
  }

  add() : void {
    if(!this.competition.id || this.competition.id == 0) {
      this.httpService.post("organiser/events/" + this.eventId + "/competitions", this.competition).subscribe(x => {
        this.dialogRef.close();
      });
    } else {
      this.httpService.put("organiser/events/" + this.eventId + "/competitions/" + this.competition.id, this.competition).subscribe(x => {
        this.dialogRef.close();
      });
    }
  }
}
