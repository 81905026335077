import {Component, OnDestroy, OnInit} from '@angular/core';
import {BibchipHttpService} from "../other/services/bibchip.http.service";
import {UserService} from "../other/services/user.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public roles: string;

  constructor(private httpClient: BibchipHttpService, private userService: UserService) {}

  ngOnInit(): void {
  }

}
