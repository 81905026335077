<ng-container *ngIf="event">
  <mat-card class="dashboard-item" [ngClass]="{'published':isPublished}">
    <mat-card-content
      (click)="edit()">
      <fa-icon class="dashboard-item-icon" icon="edit"></fa-icon> <br />
      <span translate>Edit</span>
    </mat-card-content>
  </mat-card>

<mat-card class="dashboard-item" [ngClass]="{'published':isPublished}">
  <mat-card-content
    [routerLink]="'/organiser/events/' + event.id + '/competitions'"
    (click)="navigationService.addCurrentPage(getNavigationText('Competitions'))">
    <fa-icon class="dashboard-item-icon" icon="flag-checkered"></fa-icon> <br />
    <span translate>Competitions</span>
  </mat-card-content>
</mat-card>

<mat-card class="dashboard-item" [ngClass]="{'published':isPublished}">
  <mat-card-content
    [routerLink]="'/organiser/events/' + event.id + '/categories'"
    (click)="navigationService.addCurrentPage(getNavigationText('Categories'))">
    <fa-icon class="dashboard-item-icon" icon="th-list"></fa-icon> <br />
    <span translate>Categories</span>
  </mat-card-content>
</mat-card>

  <mat-card class="dashboard-item" [ngClass]="{'published':isPublished}">
    <mat-card-content
      [routerLink]="'/organiser/events/' + event.id + '/extra-fields'"
      (click)="navigationService.addCurrentPage(getNavigationText('ExtraFields'))">
      <fa-icon class="dashboard-item-icon" icon="info-circle"></fa-icon> <br />
      <span translate>ExtraFields</span>
    </mat-card-content>
  </mat-card>

  <mat-card class="dashboard-item" [ngClass]="{'published':isPublished}">
    <mat-card-content
      (click)="confirmationEmail()">
      <fa-icon class="dashboard-item-icon" icon="envelope"></fa-icon> <br />
      <span translate>ConfirmationEmail</span>
    </mat-card-content>
  </mat-card>

  <mat-card class="dashboard-item" [ngClass]="{'published':isPublished}">
    <mat-card-content
      [routerLink]="'/organiser/preview/subscribe/' + event.id"
      (click)="navigationService.addCurrentPage(getNavigationText('Subscribe') + ' !!PREVIEW!!')">
      <fa-icon class="dashboard-item-icon" icon="vial"></fa-icon> <br />
      <span translate>Preview</span>
    </mat-card-content>
  </mat-card>

<mat-card class="dashboard-item">
  <mat-card-content

    [routerLink]="'/organiser/events/' + event.id + '/participants'"
    (click)="navigationService.addCurrentPage(getNavigationText('Participants'))">
    <fa-icon class="dashboard-item-icon" icon="users"></fa-icon> <br />
    <span translate>Participants</span>
  </mat-card-content>
</mat-card>

<mat-card class="dashboard-item" [ngClass]="{'published':isPublished}">
  <mat-card-content
    (click)="invoiceInfo()">
    <fa-icon class="dashboard-item-icon" icon="file-invoice-dollar"></fa-icon> <br />
    <span translate>InvoiceInfo</span>
  </mat-card-content>
</mat-card>

  <mat-card class="dashboard-item">
    <mat-card-content
      (click)="costsAndProfits()">
      <fa-icon class="dashboard-item-icon" icon="money-bill-wave"></fa-icon> <br />
      <span translate>Profits</span>
    </mat-card-content>
  </mat-card>

  <mat-card class="dashboard-item" [ngClass]="{'published':isPublished}">
    <mat-card-content
      (click)="publish()">
      <fa-icon class="dashboard-item-icon" icon="globe-americas"></fa-icon> <br />
      <span translate>Publish</span>
    </mat-card-content>
  </mat-card>
</ng-container>
