import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {BibchipHttpService} from "../../../other/services/bibchip.http.service";
import {UserService} from "../../../other/services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent, ConfirmDialogModel} from "../../../other/shared/confirm-dialog/confirm-dialog.component";
import {OrganiserEvent} from "../../../other/model/organiserEvent";
import {NavigationService} from "../../../other/services/navigation.service";
import {DatePipe} from "@angular/common";
import {Subscription} from "rxjs";
import {OrganiserCompetition} from "../../../other/model/organiserCompetition";
import {TranslateService} from "@ngx-translate/core";
import {OrganiserCategory} from "../../../other/model/organiserCategory";

@Component({
  selector: 'app-organiser-participant-overview',
  templateUrl: './participant-overview.component.html',
  styleUrls:['./participant-overview.component.scss'],
  providers:[DatePipe]
})
export class OrganiserParticipantOverviewComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = [ 'name', 'city', 'gender', 'birthdate','category', 'payed', 'actions'];
  public participants: MatTableDataSource<any>;
  public event: OrganiserEvent;
  private subscriptions: Subscription[];
  public categories: OrganiserCategory[];

  constructor(@Inject('BASE_URL') private baseUrl: string, public dialog: MatDialog, private httpService: BibchipHttpService, private userService: UserService, private activatedRoute: ActivatedRoute, private router: Router, public navigationService: NavigationService, private datePipe: DatePipe, private translateService : TranslateService) {
    this.subscriptions = [];
  }

  deleteConfirmation(item: any): void {

    const dialogData = new ConfirmDialogModel("ConfirmDelete","AreYouSureYouWantToDeleteX", item.lastName + ' ' + item.firstName);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult)
        this.delete(item);
    });
  }

  togglePayed(participant: any) : void {
    this.httpService.put("organiser/events/" + this.event.id + '/participants/' + participant.id  + "/toggle-payed", null).subscribe(x => {
      participant.paymentDone = !participant.paymentDone;
    });
  }

  export() : void {
    window.open(this.baseUrl + 'api/organiser/events/' + this.event.id + '/participants/export');
  }

  edit(item: any) {
   // const dialogRef = this.dialog.open(OrganiserManageCompetitionComponent);
    //dialogRef.componentInstance.loadCompetition(comp, this.event.id);
    //dialogRef.afterClosed().subscribe(x => { this.loadCompetitions(); });
  }

  delete(item: any): void {
    this.httpService.delete("organiser/events/" + this.event.id + '/participants/' + item.id).subscribe(x => {
      this.loadParticipants();
    });
  }

  loadParticipants() {
    this.httpService.get<any[]>("organiser/events/" + this.event.id + "/participants").subscribe(x => {
      x.forEach(y => {
        y.category = this.categories.filter(z => z.id == y.categoryId)[0];
      });
      this.participants = new MatTableDataSource<any>(x);
    });
  }

  ngOnInit(): void {
    this.subscriptions.push(this.activatedRoute.paramMap.subscribe(x => {
      let evId = x.get('id');
      this.httpService.get<OrganiserEvent>('organiser/events/' + evId).subscribe(y => {
        this.event = y;
        this.httpService.get<OrganiserCategory[]>('organiser/events/' + evId + '/categories').subscribe(cats => {
          this.categories = cats;
          this.loadParticipants();
          this.navigationService.lastNavigationAction().subscribe(link => {
            if(!link) {
              this.navigationService.addNavigationAction('/organiser/events/' + evId + '/dashboard', y.name + ' (' + this.translateService.instant('Participants') + ')');
            }
          }).unsubscribe();
        });
      });
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }
}
