<h2 mat-dialog-title>
  <span *ngIf="!extraField.id || extraField.id == 0" translate>New</span>
  <span *ngIf="extraField.id && extraField.id != 0" translate>Edit</span>
</h2>
<mat-dialog-content class="mat-typography">
  <form class="example-form" #fieldForm="ngForm">
    <mat-form-field class="example-full-width">
      <input matInput required placeholder="{{ 'Name' | translate }}" name="name" [(ngModel)]="extraField.name">
    </mat-form-field>
    <div class="example-full-width">
      <mat-checkbox  name="isRequired" [(ngModel)]="extraField.isRequired">
        {{ 'Required' | translate }}
      </mat-checkbox>
    </div>
    <mat-form-field class="example-full-width">
      <mat-label><span translate>Type</span></mat-label>
      <mat-select [(ngModel)]="extraField.type" name="fieldtype" required (selectionChange)="log()">
        <mat-option *ngFor="let t of (types | asenumlist)" [value]="t.value">
          {{ 'DataTypes.' + t.key | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="example-full-width" *ngIf="extraField.type == listType">
    <mat-chip-list #chipList>
      <mat-chip *ngFor="let item of extraField.listItems"
                 (removed)="removeFromList(item)">
        {{item}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input [placeholder]="'ListItems' | translate"
             [matChipInputFor]="chipList"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             [matChipInputAddOnBlur]="true"
             (matChipInputTokenEnd)="addListItem($event)">
    </mat-chip-list>
  </mat-form-field>
    <div class="example-full-width">
      <mat-checkbox  name="OnlyVisibleWhenOtherFieldIsFilledIn" [(ngModel)]="dependentOn">
        {{ 'OnlyVisibleWhenOtherFieldIsFilledIn' | translate }}
      </mat-checkbox>
    </div>
    <mat-form-field class="example-full-width" *ngIf="dependentOn">
      <mat-label><span translate>DependentField</span></mat-label>
      <mat-select [(ngModel)]="extraField.onlyVisibleWhenThisIsFilledIn" name="otherfield" required>
        <mat-option *ngFor="let f of allFields" [value]="f">
          {{ f.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="example-full-width">
      <mat-checkbox  name="startsNewStep" [(ngModel)]="extraField.startsNewStepInSubscriptionProcess">
        {{ 'StartsNewStep' | translate }}
      </mat-checkbox>
    </div>
    <mat-form-field class="example-full-width" *ngIf="extraField.startsNewStepInSubscriptionProcess">
      <input matInput required placeholder="{{ 'StepName' | translate }}" name="stepName" [(ngModel)]="extraField.newStepTitle">
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close><span translate>Cancel</span></button>
  <button mat-button [disabled]="!fieldForm.valid" (click)="add()" cdkFocusInitial>
    <span *ngIf="!extraField.id || extraField.id == 0" translate>Add</span>
    <span *ngIf="extraField.id && extraField.id != 0" translate>Save</span>
  </button>
</mat-dialog-actions>
