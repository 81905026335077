import {Inject, Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {ErrorItem, ValidationError} from "../model/validationError";
import {NavigationStart, Router , Event as NavigationEvent} from "@angular/router";
import {filter} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";

@Injectable()
export class ErrorService {
  private error: BehaviorSubject<ValidationError>;

  constructor(private router: Router, private matDialog: MatDialog) {
    this.error = new BehaviorSubject<ValidationError>(null);
    this.router.events
      .pipe(
        filter((event: NavigationEvent) =>  (event instanceof NavigationStart)))
      .subscribe((event: NavigationStart) =>  this.clearError());
    this.matDialog.afterAllClosed.subscribe(x => {
      this.clearError();
    });
  }

  clearError(): void {
    this.error.next(null);
  }

  getError(): Observable<ValidationError> {
    return this.error.asObservable();
  }

  errorReceived(error: any): void {
    let val = new ValidationError();
    val.status = error.status;
    val.traceId = error.error.traceId;
    val.title = error.error.title;
    val.errors = [];

    for (let prop in error.error.errors) {
      if (Object.prototype.hasOwnProperty.call(error.error.errors, prop)) {
        error.error.errors[prop].forEach(message => {
          let err = new ErrorItem();
          err.name = prop;
          err.message = message;
          val.errors.push(err);
        });
      }
    }

    this.newErrorReceived(val);
  }

  private newErrorReceived(err: ValidationError): void {
    this.error.next(err);
  }
}
