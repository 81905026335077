import { Injectable, Inject } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {PaymentMethod} from "../model/organiserEvent";
import * as signalR from '@microsoft/signalr';
import {HubConnection} from "@microsoft/signalr";
import {BehaviorSubject, Observable} from "rxjs";

export class PaymentDoneInfo {
  participantId: number;
  successful: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PaymentService {

  private hub: HubConnection;
  private connectionId: string;
  private paymentDoneSubject: BehaviorSubject<PaymentDoneInfo>;

  constructor(private translateService: TranslateService) {
    this.paymentDoneSubject = new BehaviorSubject<PaymentDoneInfo>(null);
    this.hub = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl("/payments")
      .build();
    this.hub.start().then(() => {

      console.log('SignalR Connected!');
      this.hub.invoke('getConnectionId')
        .then((connId) => {
          console.log('connectionid: ', connId);
          this.connectionId = connId;
        });
    }).catch(function (err) {
      return console.error(err.toString());
    });

    this.hub.on("PaymentCallbackReceived", (participantId, successful) => {
      this.paymentDoneSubject.next({ participantId: participantId, successful: successful });
    });
  }

  paymentDone(): Observable<PaymentDoneInfo> {
    return this.paymentDoneSubject.asObservable();
  }

  getConnectionId() {
    return this.connectionId;
  }

  getPaymentMethods(includeManual: boolean): PaymentMethodItem[] {
    let possibilities = [
      { id : 1, name : this.translateService.instant('Paypal') },
      { id : 2, name: this.translateService.instant('Bancontact') }];
    if(includeManual) {
      possibilities.push( { id : 1000, name: this.translateService.instant('Manual') });
    }
    return possibilities;
  }

  getMethodsFromEnum(method: PaymentMethod) : number[] {
    let selectedMethods = [];
    var methodTotal = method;

    if(methodTotal >= 1000) {
      selectedMethods.push(1000);
      methodTotal -= 1000;
    }

    if(methodTotal >= 2) {
      selectedMethods.push(2);
      methodTotal -= 2;
    }

    if(methodTotal >= 1) {
      selectedMethods.push(1);
      methodTotal -= 1;
    }
    return selectedMethods;
  }
}

export class PaymentMethodItem {
  id: number;
  name: string;
}
