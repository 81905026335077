import {Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {BibchipHttpService} from "../../../other/services/bibchip.http.service";
import {UserService} from "../../../other/services/user.service";
import {MatDialogRef} from "@angular/material/dialog";
import {OrganiserCompetition} from "../../../other/model/organiserCompetition";
import {OrganiserExtraField} from "../../../other/model/organiserExtraField";
import {OrganiserCategory} from "../../../other/model/organiserCategory";
import {distinctList} from "../../../other/utils/filter";
import {MatCheckboxChange} from "@angular/material/checkbox";

@Component({
  selector: 'app-organiser-couple-extra-field',
  templateUrl: './couple-extra-field.component.html',
  styleUrls:['./couple-extra-field.component.scss']
})
export class OrganiserCoupleExtraFieldComponent implements OnInit {

  public extraField: OrganiserExtraField;
  private eventId: number;
  public competitions: any[];
  public categories: OrganiserCategory[];

  constructor(public dialogRef: MatDialogRef<OrganiserCoupleExtraFieldComponent>, private httpService: BibchipHttpService, private userService: UserService, private router: Router) {
    this.extraField = new OrganiserExtraField();
  }

  ngOnInit(): void {

  }

  loadExtraField(field: OrganiserExtraField, eventId: number): void {
    this.extraField = field;
    this.eventId = eventId;
    this.httpService.get<OrganiserCategory[]>("organiser/events/" + this.eventId + "/categories").subscribe(x => {
      this.categories = x;
      this.competitions = distinctList(x.map(y => y.organiserCompetition), y => y.id);
      (<any> this.extraField).categories.forEach(y => {
        (<any>this.categories.filter(z => z.id == y.id)[0]).checked = true;
      });
      this.competitions.forEach(y => this.toggleCatStatus(y));
    });
  }

  findCategories(competition: OrganiserCompetition): any[] {
    return this.categories.filter(x => x.organiserCompetition.id == competition.id);
  }

  save() : void {
      this.httpService.put("organiser/events/" + this.eventId + "/extra-fields/" + this.extraField.id + "/couple", (<any[]>this.categories).filter(x => x.checked).map(x => x.id)).subscribe(x => {
        this.dialogRef.close();
      });
  }

  toggleCompStatus(ev: MatCheckboxChange, comp: OrganiserCompetition) {
      let cats = this.findCategories(comp);
      cats.forEach(x => {
        x.checked = ev.checked;
      });
  }

  toggleCatStatus(comp: any) {
    let cats = this.findCategories(comp);
    if(cats.filter(x => x.checked).length == 0)
      comp.checked = false;
    else if(cats.filter(x => x.checked).length == cats.length)
      comp.checked = true;
    else {
      comp.checked = null;
    }
  }
}
