<h3 class="title" translate>Categories</h3>
<div class="table-container" *ngIf="categories && categories.data.length > 0">
  <mat-table [dataSource]="categories">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef translate>Name</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label" translate>Name</span>
        {{element.name}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="competition">
      <mat-header-cell *matHeaderCellDef translate>Competition</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label" translate>Competition</span>
        {{element.organiserCompetition?.name}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="gender">
      <mat-header-cell *matHeaderCellDef translate>Gender</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label" translate>Gender</span>
        {{element.gender | genderText | async }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell class="actions" *matHeaderCellDef translate>Actions</mat-header-cell>
      <mat-cell class="actions" *matCellDef="let element">
        <span class="mobile-label" translate>Actions</span>
        <button matTooltip="{{ 'Edit' | translate }}" mat-icon-button (click)="edit(element)">
          <mat-icon>edit</mat-icon>
        </button>
        <button  matTooltip="{{ 'Delete' | translate }}" mat-icon-button (click)="deleteConfirmation(element)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
<div class="no-items" *ngIf="!categories || categories.data.length == 0">
  <span translate>YouDontHaveCreatedAnyCategoriesYet</span>
</div>
<!--
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
-->

<button class="add-item" mat-mini-fab color="accent" (click)="add()">
  <mat-icon>add</mat-icon>
</button>
