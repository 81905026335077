import {OrganiserCompetition} from "./organiserCompetition";

export class OrganiserCategory {
  id: number;
  name: string;
  gender: number;
  competitionId: number;
  maxNumberOfParticipants?: number;
  price?: number;
  ranges: Range[];
  organiserCompetition: OrganiserCompetition;

  constructor() {
    this.ranges = [];
  }
}

export class Range {
  id: number;
  type: RangeType;
  start: number;
  end: number;
  addedOn?: number;
}

export enum RangeType {
  Age,
  BirthYear,
  Number
}

/*
        public int Id { get; set; }
        public string Name { get; set; }
        public string DurationOrDistance { get; set; }
        public int? MaxNumberOfParticipants { get; set; }
 */
