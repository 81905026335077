<div class="table-container" *ngIf="participants && participants.data.length > 0">
  <mat-table [dataSource]="participants">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef translate>Name</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label" translate>Name</span>
        {{element.lastName}} {{ element.firstName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="city">
      <mat-header-cell  *matHeaderCellDef translate>City</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label" translate>City</span>
        {{element.city}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="gender">
      <mat-header-cell  *matHeaderCellDef translate>Gender</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label" translate>Gender</span>
        {{element.gender | genderText | async }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="birthdate">
      <mat-header-cell  *matHeaderCellDef translate>Birthdate</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label" translate>Birthdate</span>
        {{element.birthDate | date: 'dd-MM-yyyy' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="payed">
      <mat-header-cell *matHeaderCellDef translate>Paid</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label" translate>Paid</span>
        <mat-checkbox color="accent" [checked]="element.paymentDone" disabled="true">
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="category">
      <mat-header-cell  *matHeaderCellDef translate>Competition</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label" translate>Competition</span>
        {{element.category.organiserCompetition.name }} ({{element.category.name }})
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell class="actions" *matHeaderCellDef translate>Actions</mat-header-cell>
      <mat-cell class="actions" *matCellDef="let element">
        <span class="mobile-label" translate>Actions</span>
       <!-- <button matTooltip="{{ 'Edit' | translate }}" mat-icon-button (click)="edit(element)">
          <mat-icon>edit</mat-icon>
        </button>-->
        <button *ngIf="!element.paymentDone"  matTooltip="{{ 'SetParticipantAsPayed' | translate }}" mat-icon-button (click)="togglePayed(element)">
          <span class="fa-icon-button"><fa-icon icon="euro-sign"></fa-icon></span>
        </button>
        <button  *ngIf="element.paymentDone" matTooltip="{{ 'SetParticipantAsNotPayed' | translate }}" mat-icon-button (click)="togglePayed(element)">
          <span class="fa-icon-button"><fa-icon [icon]="['fab', 'creative-commons-nc-eu']"></fa-icon></span>
        </button>
        <button  matTooltip="{{ 'Delete' | translate }}" mat-icon-button (click)="deleteConfirmation(element)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
<div class="no-items" *ngIf="!participants || participants.data.length == 0">
  <span translate>NoParticipantsSubscribedYet</span>
</div>
<!--
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
-->
<button class="export-list" mat-mini-fab color="accent" (click)="export()">
  <fa-icon icon="file-export"></fa-icon>
</button>
