import {Component, OnDestroy, OnInit } from '@angular/core';
import {BibchipHttpService} from "../../../other/services/bibchip.http.service";
import {UserService} from "../../../other/services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent, ConfirmDialogModel} from "../../../other/shared/confirm-dialog/confirm-dialog.component";
import {OrganiserEvent} from "../../../other/model/organiserEvent";
import {NavigationService} from "../../../other/services/navigation.service";
import {DatePipe} from "@angular/common";
import {Subscription} from "rxjs";
import {OrganiserCategory} from "../../../other/model/organiserCategory";
import {TranslateService} from "@ngx-translate/core";
import {OrganiserManageCategoryComponent} from "./manage-category.component";

@Component({
  selector: 'app-organiser-category-overview',
  templateUrl: './category-overview.component.html',
  styleUrls:['./category-overview.component.scss'],
  providers:[DatePipe]
})
export class OrganiserCategoryOverviewComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = [ 'name', 'competition', 'gender', 'actions'];
  public categories: MatTableDataSource<OrganiserCategory>;
  public event: OrganiserEvent;
  private subscriptions: Subscription[];

  constructor(public dialog: MatDialog, private httpService: BibchipHttpService, private userService: UserService, private activatedRoute: ActivatedRoute, private router: Router, public navigationService: NavigationService, private datePipe: DatePipe, private translateService: TranslateService) {
    this.subscriptions = [];
  }

  add() {
    const dialogRef = this.dialog.open(OrganiserManageCategoryComponent);
    dialogRef.componentInstance.loadCategory(new OrganiserCategory(), this.event.id);
    dialogRef.afterClosed().subscribe(x => { this.loadCategories(); });
  }

  deleteConfirmation(cat: OrganiserCategory): void {

    const dialogData = new ConfirmDialogModel("ConfirmDelete","AreYouSureYouWantToDeleteX", cat.name);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult)
        this.delete(cat);
    });
  }

  edit(cat: OrganiserCategory) {
    const dialogRef = this.dialog.open(OrganiserManageCategoryComponent);
    dialogRef.componentInstance.loadCategory(cat, this.event.id);
    dialogRef.afterClosed().subscribe(x => { this.loadCategories(); });
  }

  delete(cat: OrganiserCategory): void {
    this.httpService.delete("organiser/events/" + this.event.id + '/categories/' + cat.id).subscribe(x => {
      this.loadCategories();
    });
  }

  loadCategories() {
    this.httpService.get<OrganiserCategory[]>("organiser/events/" + this.event.id + "/categories").subscribe(x => {
      this.categories = new MatTableDataSource<OrganiserCategory>(x);
    });
  }

  ngOnInit(): void {
    this.subscriptions.push(this.activatedRoute.paramMap.subscribe(x => {
      let evId = x.get('id');
      this.httpService.get<OrganiserEvent>('organiser/events/' + evId).subscribe(y => {
        this.event = y;
        this.loadCategories();
         this.navigationService.lastNavigationAction().subscribe(link => {
          if(!link) {
            this.navigationService.addNavigationAction('/organiser/events/' + evId + '/dashboard', y.name + ' (' + this.translateService.instant('Categories') + ')');
          }
        }).unsubscribe();
      });
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }
}
