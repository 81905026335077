import {Component} from "@angular/core";
import {Observable} from "rxjs";
import {ErrorItem, ValidationError} from "../../model/validationError";
import {ErrorService} from "../../services/error.service";

@Component({
  selector: 'app-error-shower',
  templateUrl: './error-shower.component.html',
  styleUrls: ['./error-shower.component.css']
})
export class ErrorShowerComponent {

  public error$: Observable<ValidationError>;

  constructor(private errorService: ErrorService) {
    this.error$ = this.errorService.getError();
  }

  close(): void {
    this.errorService.clearError();
  }

  getErrors(err: ValidationError): ErrorItem[] {
    if (!err || !err.errors)
      return [];
    return err.errors.filter(x => true);
    //return err.errors.filter(x => x.name == "");
  }
}
