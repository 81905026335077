import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {User} from "../model/user";
import {BibchipHttpService} from "./bibchip.http.service";
import * as jwt_decode from "jwt-decode";
import {TokenObject} from "../model/tokenObject";
import {map} from "rxjs/operators";
import {Roles} from "../model/roles";
import {NavigationService} from "./navigation.service";
import {Router} from "@angular/router";


@Injectable({providedIn:"root"})
export class UserService {

  private static ROLES_IDENTIFIER : string = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
  private static NAME_IDENTIFIER : string = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name";
  private static SID_IDENTIFIER : string = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid";

  private userSubject: BehaviorSubject<User>;
  private isSignedInSubject: BehaviorSubject<boolean>;

  constructor(private  httpService: BibchipHttpService, private navigationService: NavigationService, private router: Router) {
    this.userSubject = new BehaviorSubject<User>(null);
    this.isSignedInSubject = new BehaviorSubject<boolean>(false);
    this.checkLocalStorage();
  }

  private checkLocalStorage() {
    let u = localStorage.getItem("USER");
    if(u != null) {
      let us = JSON.parse(u);
      let expires = new Date(Date.parse(us.tokenExpiresAt));
      let refreshTokenExpires = new Date(Date.parse(us.refreshTokenExpiresAt));
      if(expires < new Date()) {
        if(refreshTokenExpires < new Date()) {
          this.signOut();
        } else {
          setTimeout(() => this.refresh(us), 10);
        }
      } else {
        this.autoRefresh(us);
        this.userSubject.next(us);
        this.isSignedInSubject.next(true);
      }
    }
  }

  private autoRefresh(u: User) : void {
    const expires = new Date(Date.parse(u.tokenExpiresAt));
    const timeoutInMiliseconds = expires.getTime() - (new Date()).getTime();
    setTimeout(() => {
      this.refresh(u);
    }, timeoutInMiliseconds);
  }

  private refresh(u: User) {
    this.httpService.postWithResponse<User>("account/refresh", { refreshToken: u.refreshToken } ).subscribe(x => {
      this.signIn(x);
    }, error => this.signOut());
  }

  public signIn(u: User): void {
    u.tokenInfo = this.parseToken(u.token);
    localStorage.setItem("USER", JSON.stringify(u));
    this.userSubject.next(u);
    this.isSignedInSubject.next(true);
    this.autoRefresh(u);
  }

  private parseToken(token: string) : TokenObject {
    let tokenObject: TokenObject;
    const dec = jwt_decode(token);
    tokenObject =new TokenObject();
    tokenObject.aud = dec.aud;
    tokenObject.exp = dec.exp;
    tokenObject.name = dec[UserService.NAME_IDENTIFIER];
    tokenObject.roles = dec[UserService.ROLES_IDENTIFIER];
    tokenObject.sid = dec[UserService.SID_IDENTIFIER];
    return tokenObject;
  }

  public signOut() : void {
    localStorage.removeItem("USER");
    this.userSubject.next(null);
    this.isSignedInSubject.next(false);
    this.navigationService.clearNavigation();
    this.router.navigateByUrl('/signin');
  }

  public isSignedIn(): Observable<boolean> {
    return this.isSignedInSubject.asObservable();
  }

  public user(): Observable<User> {
    return this.userSubject.asObservable();
  }

  public currentUser() : User {
    return this.userSubject.value;
  }

  public isInRole(role: string): boolean {
    return UserService.isInRoleForUser(this.userSubject.value, role);
  }

  private static isInRoleForUser(u: User, role: string): boolean {
    if(!u || !u.tokenInfo.roles)
      return false;
    return u.tokenInfo.roles.indexOf(role) != -1;
  }

  public isAdmin(): Observable<boolean> {
     return this.userSubject.asObservable().pipe(map(x => UserService.isInRoleForUser(x,Roles.Admin)));
  }

  public isOrganiser(): Observable<boolean> {
    return this.userSubject.asObservable().pipe(map(x => UserService.isInRoleForUser(x,Roles.EventOrganiser)));
  }

  public roles() : string[] {
    if(!this.userSubject.value || !this.userSubject.value.tokenInfo.roles)
      return [];
    return this.userSubject.value.tokenInfo.roles;
  }
}
