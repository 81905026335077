export class OrganiserCompetition {
  id: number;
  name: string;
  durationOrDistance?: string;
  maxNumberOfParticipants?: number;
  price: number;
}

/*
        public int Id { get; set; }
        public string Name { get; set; }
        public string DurationOrDistance { get; set; }
        public int? MaxNumberOfParticipants { get; set; }
 */
