import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class PaginatorI18n {

  private paginator: MatPaginatorIntl;

   constructor(private readonly translate: TranslateService) {
      this.translate.onLangChange.subscribe(x => {
          this.translateLabels(this.paginator);
      });
  }

  getPaginatorIntl(): MatPaginatorIntl {
    this.translate.setDefaultLang('be');
    const paginatorIntl = new MatPaginatorIntl();
    this.translateLabels(paginatorIntl);
    this.paginator = paginatorIntl;
    return paginatorIntl;
  }

  translateLabels(paginatorIntl : MatPaginatorIntl) : void {
    paginatorIntl.itemsPerPageLabel = this.translate.instant('Paginator.ITEMS_PER_PAGE_LABEL');
    paginatorIntl.nextPageLabel = this.translate.instant('Paginator.NEXT_PAGE_LABEL');
    paginatorIntl.previousPageLabel = this.translate.instant('Paginator.PREVIOUS_PAGE_LABEL');
    paginatorIntl.firstPageLabel = this.translate.instant('Paginator.FIRST_PAGE_LABEL');
    paginatorIntl.lastPageLabel = this.translate.instant('Paginator.LAST_PAGE_LABEL');
    paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);
  }

  private getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return this.translate.instant('Paginator.RANGE_PAGE_LABEL_1', { length });
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return this.translate.instant('Paginator.RANGE_PAGE_LABEL_2', { startIndex: startIndex + 1, endIndex, length });
  }
}
