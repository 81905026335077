import { Injectable } from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import {Observable, throwError} from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {SpinnerService} from "../services/spinner.service";


@Injectable()
export class LoaderHttpInterceptor implements HttpInterceptor {
  private counter: number;

  constructor(private router: Router, private spinner: SpinnerService) {
    this.counter = 0;
  }

  initLoader(): void {
    this.counter++;
    this.spinner.show();
  }

  hideLoader(): void {
    this.counter--;
    if(this.counter == 0)
      this.spinner.hide();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.initLoader();
    return next.handle(req)
      .pipe(catchError((err) => {
        this.hideLoader();
       this.spinner.resetToDefaultText();
        return throwError(err);
      })).pipe(tap((event: HttpEvent<any>) => {
        if(event instanceof HttpResponse) {
          this.hideLoader();
        }
      }));
  }
}
