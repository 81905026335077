import {Component, OnInit} from '@angular/core';
import {BibchipHttpService} from "../other/services/bibchip.http.service";
import {UserService} from "../other/services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Result, ResultCompetition, ResultEvent} from "../other/model/resultEvent";
import {NavigationService} from "../other/services/navigation.service";

@Component({
  selector: 'app-result-competition',
  templateUrl: './result-competition.component.html',
  styleUrls:['./result-competition.component.scss']
})
export class ResultCompetitionComponent implements OnInit {

  private eventId: number;
  public event: ResultEvent;
  private competitionId: number;
  public competition: ResultCompetition;
  public selectedView: string;

  public visibleLabels: any;
  public results: ResultView[];
  public filteredResults: Result[];

  public searchValue: string;

  public COLUMN_DEFS_COMP = ['place','startnumber','name','birthdate','city', 'category','finishTime'];
  public COLUMN_DEFS_CAT = ['cat-place','startnumber','name','birthdate','city', 'finishTime'];

  constructor(private httpService: BibchipHttpService, private userService: UserService, private router: Router, private activatedRoute: ActivatedRoute, private navigationService: NavigationService) {
    this.selectedView = "competition";
    this.visibleLabels = { competition : this.COLUMN_DEFS_COMP, category : this.COLUMN_DEFS_CAT };
    this.results = [];
    this.filteredResults = [];
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      if(params.has('id')) {
        this.eventId = <number><unknown>params.get('id');
        this.loadEvent(
          () => {
            if(params.has('competitionId')) {
              this.competitionId = <number><unknown>params.get('competitionId');
              this.competition = this.event.competitions.filter(x => x.id == this.competitionId)[0];
              this.navigationService.lastNavigationAction().subscribe(link => {
                if(!link) {
                  this.navigationService.addNavigationAction('/results', this.event.name);
                  this.navigationService.addNavigationAction('/results/' + this.event.id, this.event.name + " - " + this.competition.name);
                }
              }).unsubscribe();
              this.filterResults();
            }
          });
      }
    });
  }

  loadEvent(onLoaded: () => void) : void {
    this.httpService.get<ResultEvent>('results/' + this.eventId).subscribe(x => {
      this.event = x;
      onLoaded();
    });
  }

  viewChanged() {
    if(this.selectedView == 'competition') {
      let resView = new ResultView();
      resView.results = this.filteredResults;
      this.results = [resView];
    } else {
      this.results = [];
      this.competition.categories.forEach(x => {
        this.results.push({
          name: x,
          results: this.filteredResults.filter(y => y.categoryName == x)
        });
      });
    }
  }

  filterResults() {
    if(!this.competition) {
      this.filteredResults = [];}
    else if(!this.searchValue) {
      this.filteredResults = this.competition.results;
    } else {
      let parts = this.searchValue.split(' ').filter(x => x);
      this.filteredResults = this.competition.results;
      parts.forEach(searchPart => {
        this.filteredResults = this.filteredResults.filter(x =>
          (x.fullname.toLowerCase().indexOf(searchPart.toLowerCase()) > -1) ||
          (x.city && x.city.toLowerCase().indexOf(searchPart.toLowerCase()) > -1) ||
          (x.startNumber.toString().indexOf(searchPart) > -1));
      });
    }
    this.viewChanged();
  }
}

 class ResultView {
  name: string;
  results: Result[];
}
