<h3 class="title" translate>MyEvents</h3>
<div class="table-container" *ngIf="events && events.data.length > 0">
  <mat-table [dataSource]="events">
    <ng-container matColumnDef="name">
      <mat-header-cell class="name" *matHeaderCellDef translate>Name</mat-header-cell>
      <mat-cell class="name" *matCellDef="let element">
        <span class="mobile-label" translate>Name</span>
        {{element.name}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="date">
      <mat-header-cell class="date" *matHeaderCellDef translate>Date</mat-header-cell>
      <mat-cell class="date" *matCellDef="let element">
        <span class="mobile-label" translate>Date</span>
        {{element.date | date: 'dd-MM-yyyy'}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="isLive">
      <mat-header-cell class="is-live" *matHeaderCellDef translate> IsLiveShort</mat-header-cell>
      <mat-cell class="is-live" *matCellDef="let element">
        <span class="mobile-label" translate>IsLiveShort</span>
        <mat-checkbox color="accent" [checked]="element.isLive" disabled="true">

        </mat-checkbox>
         </mat-cell>
    </ng-container>

    <ng-container matColumnDef="subscriptionEnd">
      <mat-header-cell class="subscription-date" *matHeaderCellDef translate>EndOfSubscriptions</mat-header-cell>
      <mat-cell class="subscription-date" *matCellDef="let element">
        <span class="mobile-label" translate>EndOfSubscriptions</span>
        {{element.subscriptionEnd | date: 'dd-MM-yyyy'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell class="actions" *matHeaderCellDef translate>Actions</mat-header-cell>
      <mat-cell class="actions" *matCellDef="let element">
        <span class="mobile-label" translate>Actions</span>
        <button matTooltip="{{ 'Edit' | translate }}" mat-icon-button (click)="edit(element)">
          <mat-icon>edit</mat-icon>
        </button>
        <button matTooltip="{{ 'GoToDashboard' | translate }}" [routerLink]="'/organiser/events/' + element.id + '/dashboard'" mat-icon-button (click)="navigationService.addCurrentPage(element.name + ' (' + datePipe.transform(element.date, 'dd-MM-yyyy') +')')">
          <mat-icon>dashboard</mat-icon>
        </button>
        <button  matTooltip="{{ 'Delete' | translate }}" mat-icon-button (click)="deleteConfirmation(element)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
<div class="no-items" *ngIf="!events || events.data.length == 0">
  <span translate>YouDontHaveCreatedAnyEventsYet</span>
</div>
<!--
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
-->

<button class="add-item" mat-mini-fab color="accent" (click)="add()">
  <mat-icon>add</mat-icon>
</button>
