import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {BibchipHttpService} from "../../other/services/bibchip.http.service";
import {UserService} from "../../other/services/user.service";
import {UserBase} from "../../other/model/user.base";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-admin-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls:['./manage-user.component.scss']
})
export class ManageUserComponent implements OnInit {

  public user: UserBase;
  public roles: any[];
  public hasAnyRole: boolean;

  constructor(public dialogRef: MatDialogRef<ManageUserComponent>, private httpService: BibchipHttpService, private userService: UserService, private router: Router) {
    this.user = new UserBase();
    this.hasAnyRole = false;
  }

  ngOnInit(): void {
    this.httpService.get<any[]>("users/roles").subscribe(x => {
      this.roles = x;
    });
  }

  loadUser(u: UserBase): void {
    this.user = u;
    this.hasAnyRole = this.user.roles.length > 0;
  }

  onRoleChange(event) {
    if(event.checked) {
      this.user.roles.push(event.source.value.name);
    } else {
      const i = this.user.roles.findIndex(x => x === event.source.value.name);
      this.user.roles.splice(i, 1);
    }
    this.hasAnyRole = this.user.roles.length > 0;
  }

  addUser() : void {
    if(!this.user.id || this.user.id == 0) {
      this.httpService.post("users", this.user).subscribe(x => {
        this.dialogRef.close();
      });
    } else {
      this.httpService.put("users/" + this.user.id, this.user).subscribe(x => {
        this.dialogRef.close();
      });
    }
  }
}
