import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {UserService} from "../services/user.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private userService: UserService){}

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {

    const user = this.userService.currentUser();
    if (user && new Date(Date.parse(user.tokenExpiresAt)) > new Date()) {
      const cloned = req.clone({
        headers: req.headers.set("Authorization",
          "Bearer " + user.token)
      });

      return next.handle(cloned);
    }
    else {
      return next.handle(req);
    }
  }
}
