export class OrganiserExtraField {
  id: number;
  name: string;
  isRequired: boolean;
  type: ExtraFieldType;
  onlyVisibleWhenThisIsFilledIn?: OrganiserExtraField;
  newStepTitle: string;
  startsNewStepInSubscriptionProcess: boolean;
  position: number;
  listItems: string[];
}

export enum ExtraFieldType {
  Text,
  Checkbox,
  Date,
  List
}
