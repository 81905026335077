import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {BibchipHttpService} from "../../../other/services/bibchip.http.service";
import {UserService} from "../../../other/services/user.service";
import {MatDialogRef} from "@angular/material/dialog";
import {InvoiceInfo, OrganiserEvent} from "../../../other/model/organiserEvent";
import {EditorComponent} from "@tinymce/tinymce-angular";
import {EditorService} from "../../../other/services/editor.service";

@Component({
  selector: 'app-organiser-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls:['./email-confirmation.component.scss']
})
export class OrganiserManageEmailConfirmationComponent implements OnInit {

  public event: OrganiserEvent;
  public tinyMceConfig: any;

  @ViewChild('emailTemplate', {static : true})
  public template: EditorComponent;

  public editor: any;

  constructor(private editorService: EditorService, public dialogRef: MatDialogRef<OrganiserManageEmailConfirmationComponent>, private httpService: BibchipHttpService, private userService: UserService, private router: Router) {
    this.event = new OrganiserEvent();
    this.tinyMceConfig = this.editorService.getSettings();
    this.tinyMceConfig.height = 500;
    this.tinyMceConfig.setup = editor => {
      this.editor = editor;
    }
  }

  ngOnInit(): void {

  }

  loadEvent(e: OrganiserEvent): void {
    this.event = e;
  }

  insert(field: string): void {
    let insertString = "{{" + field + "}}";
    this.editor.execCommand('mceInsertContent', false, insertString);
  }

  save() : void {
      this.httpService.put("organiser/events/" + this.event.id + "/email-confirmation", this.event).subscribe(x => {
        this.dialogRef.close();
      });
  }
}
