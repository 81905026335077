import { Injectable, Inject } from '@angular/core';
declare var tinymce: any;

@Injectable({
  providedIn: 'root',
})
export class EditorService {

  constructor(@Inject('BASE_URL') private baseUrl: string) {
  }

  getSettings(): any {
    tinymce.baseURL = this.baseUrl + 'assets/tinymce';

    return {
      inline: false,
      branding: false,
      statusbar: false,
      forced_root_block: false,
      menubar: false,
      relative_urls: false,
      images_upload_url: '/api/content/images/uploads',
      images_upload_credentials: true,
      paste_data_images : true
    };
  }
}
