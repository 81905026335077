<h2 mat-dialog-title>
  <span translate>ConfirmationEmail</span>
</h2>
<mat-dialog-content class="mat-typography">
  <form class="example-form" #eventForm="ngForm">
    <div class="full-width min-height">
   <div class="text-area">
     <mat-label>
      {{'ConfirmationEmail' | translate }}
     </mat-label>
     <editor
     #emailTemplate="ngModel"
     [(ngModel)]="event.emailTemplateForSuccessfulSubscription"
     [inline]="false"
     class="min-height"
     [init]="tinyMceConfig"
     name="emailtemplate"
     toolbar="bold italic underline"
     tinyMatFormControl
     required>
 </editor>
   </div>
    <div class="placeholders">
      <h5 translate>Placeholders</h5>
      <button class="placeholder-button" mat-raised-button (click)="insert('FirstName')">
        <span translate>Firstname</span>
      </button>
      <button class="placeholder-button" mat-raised-button (click)="insert('LastName')">
        <span translate>Lastname</span>
      </button>
      <button class="placeholder-button" mat-raised-button (click)="insert('Competition')">
        <span translate>Competition</span>
      </button>
      <button class="placeholder-button" mat-raised-button (click)="insert('Category')">
        <span translate>Category</span>
      </button>
      <button class="placeholder-button" mat-raised-button (click)="insert('Price')">
        <span translate>Price</span>
      </button>
      <button class="placeholder-button" mat-raised-button (click)="insert('Date')">
        <span translate>Date</span>
      </button>
    </div>
    </div>
  </form>
  <div class="spacer"></div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close><span translate>Cancel</span></button>
  <button mat-button [disabled]="!eventForm.valid" (click)="save()" cdkFocusInitial>
    <span  translate>Save</span>
  </button>
</mat-dialog-actions>
