import {Component, Input, OnInit} from '@angular/core';
import {BibchipHttpService} from "../other/services/bibchip.http.service";
import {SubscriptionEvent} from "../other/model/subscriptionEvent";
import {NavigationService} from "../other/services/navigation.service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls:['subscribe.component.scss'],
  providers:[DatePipe]
})
export class SubscribeComponent implements  OnInit {

  public events: SubscriptionEvent[];

  @Input()
  public componentPadding: boolean = true;

  @Input()
  public numberOfEventsToShow: number | null = null;

  constructor(private httpService: BibchipHttpService, public navigationService: NavigationService, public datePipe: DatePipe){

  }

  ngOnInit(): void {
    let query = "";
    if(this.numberOfEventsToShow != null) {
      query = "?take=" + this.numberOfEventsToShow;
    }
    this.httpService.get<SubscriptionEvent[]>('subscriptions' + query).subscribe(x => {
      this.events = x;
    });
  }
}
