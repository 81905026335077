<ng-container *ngIf="extraField.type == 0">
  <mat-form-field class="full-width" >
    <input matInput [(ngModel)]="value" [placeholder]="extraField.name" [required]="extraField.isRequired" />
  </mat-form-field>
</ng-container>
<ng-container *ngIf="extraField.type == 1">
    <mat-checkbox [(ngModel)]="value" [required]="extraField.isRequired">
      {{ extraField.name }} <span *ngIf="extraField.isRequired"> (<sup>*</sup>)</span>
    </mat-checkbox>
</ng-container>
<ng-container *ngIf="extraField.type == 2">
  <mat-form-field class="full-width">
    <input matInput [matDatepicker]="matDatepicker" [(ngModel)]="value" [placeholder]="extraField.name" [required]="extraField.isRequired">
    <mat-datepicker-toggle class="big-icon" [for]="matDatepicker" matSuffix></mat-datepicker-toggle>
    <mat-datepicker #matDatepicker></mat-datepicker>
    <mat-hint><span translate>Format</span>: dd/MM/jjjj (<span translate>ForExample</span>: 26/11/1978)</mat-hint>
  </mat-form-field>
</ng-container>
<ng-container *ngIf="extraField.type == 3">
  <mat-form-field class="full-width" *ngIf="extraField.type == 3">
    <mat-select [(ngModel)]="value" [placeholder]="extraField.name" [required]="extraField.isRequired">
      <mat-option *ngFor="let item of extraField.listItems" [value]="item">
        {{ item }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>
