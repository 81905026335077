<div class="event-container" [class.padding]="componentPadding">
    <mat-card class="event-card"  *ngFor="let ev of events">
      <div class="card-fix-container">
      <mat-card-content>
        <div class="event-card-content">
          <div class="event-card-content-title">
            <div class="title-text">
              <strong>{{ev.name}}</strong> <br />
              <span>{{ev.date | date : 'dd-MM-yyyy'}} </span><br />
              <span>({{ev.numberOfParticipants}}  <span translate>Participants</span>)</span>
            </div>

          </div>
          <div class="competition-cards">
            <div *ngFor="let comp of ev.competitions | filter: shouldHaveParticipants()" class="competition-card-container">
              <mat-card class="competition-card" >
                <mat-card-header>
                  <div mat-card-avatar class="competition-image"><mat-icon>assessment</mat-icon></div>
                  <span class="competition-subtitle"> {{ comp.name }} ({{comp.numberOfParticipants}}  <span translate>Participants</span>)</span>
                </mat-card-header>
              </mat-card>
            </div>
          </div>
          <div class="view-button-container">
            <a mat-raised-button color="accent" (click)="navigationService.addCurrentPage(ev.name)" [routerLink]="'/results/' + ev.id"><span translate>View</span></a>
          </div>
           </div>
      </mat-card-content>
      </div>
    </mat-card>
  <mat-card *ngIf="!events || events.length == 0">
    <mat-card-header>
      <mat-card-title>
        <span translate>NoEventsAvailable</span>
      </mat-card-title>

    </mat-card-header>
      <mat-card-content>
        <p translate>ThereAreNoEventsAvailableWithYourSearchCriteria</p>
      </mat-card-content>
  </mat-card>
</div>
