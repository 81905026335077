import {Injectable} from '@angular/core';
import {NgxSpinnerService} from "ngx-spinner";
import {BehaviorSubject, Observable } from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {NgxSpinner} from "ngx-spinner/lib/ngx-spinner.enum";

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {

  public spinnerObservable: Observable<NgxSpinner>;
  private defaultText: string;
  private textSubject: BehaviorSubject<string>;

  constructor(private spinner: NgxSpinnerService, private translationService: TranslateService) {
    this.spinnerObservable = this.spinner.getSpinner('default');
    this.defaultText = this.translationService.instant("Loading");
    this.textSubject = new BehaviorSubject<string>(this.defaultText);
    this.translationService.onLangChange.subscribe(x => {
      this.defaultText = this.translationService.instant("Loading");
      this.textSubject.next(this.defaultText);
    });
  }

  show(): void {
    setTimeout(() => this.spinner.show(),0);
  }

  hide(): void {
    setTimeout(() =>this.spinner.hide(),0);
  }

  public text(): Observable<string> {
    return this.textSubject.asObservable();
  }

  public updateText(newText: string): void {
    this.textSubject.next(newText);
  }

  public resetToDefaultText() : void {
    this.updateText(this.defaultText);
  }
}
