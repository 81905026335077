import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import {ErrorItem} from "../../model/validationError";
import {ErrorService} from "../../services/error.service";
import {filter, map} from "rxjs/operators";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-field-error-shower',
  templateUrl: './field-error-shower.component.html',
  styleUrls: ['./field-error-shower.component.css']
})
export class FieldErrorShowerComponent {

  public errors$: Observable<ErrorItem[]>;

  @Input()
  public fieldName: string;

  @Input()
  public form: NgForm;

  @Input()
  public controlName: string;

  constructor(private errorService: ErrorService) {
    this.errors$ = this.errorService.getError()
      .pipe(map(x => x == null? [] :  x.errors))
      .pipe(map(y => y.filter(x => this.fieldName && x.name.toUpperCase() == this.fieldName.toUpperCase())))
      .pipe(map(x => {
        if(this.form && this.form.controls[this.controlName]) {
          if(x.length > 0) {
            console.log(this.form.controls[this.controlName]);
            console.log(x);
            this.form.controls[this.controlName].setErrors(x[0]);
            this.form.controls[this.controlName].markAllAsTouched();
          } else  {
            this.form.controls[this.controlName].setErrors(null);
            this.form.controls[this.controlName].markAllAsTouched();
          }
        }
        return x;
      }));
  }
}
