<app-content [title]="competition?.name ? competition?.name + ' (' + competition?.numberOfParticipants + ' ' + ('Participants' | translate) + ')' : ''">
  <div outside-content-container>
    {{ event?.name }} ({{event?.date | date: 'dd-MM-yyyy'}})
  </div>
  <div>
    <div class="search-and-type">
      <div class="search-container">
        <mat-form-field color="primary" floatLabel="auto" class="search-bar">
          <mat-icon class="icon" matPrefix>search</mat-icon>
          <input type="search" placeholder="{{ 'SearchDot' | translate }}" (keyup)="filterResults()" matInput name="search" [(ngModel)]="searchValue"  />
            <mat-icon *ngIf="searchValue" matIconSuffix (click)="searchValue='';filterResults()">close</mat-icon>
        </mat-form-field>
      </div>
      <div class="button-toggle-group">
        <mat-button-toggle-group [(ngModel)]="selectedView"  (change)="viewChanged()" class="full-width competition-category">
        <mat-button-toggle class="full-width" value="competition">
          <button mat-button style="pointer-events:none;" color="primary"><span translate>CompleteResultList</span></button>
        </mat-button-toggle>
        <mat-button-toggle value="category">
          <button mat-button style="pointer-events:none;" color="primary"><span translate>ResultListPerCategory</span></button>
        </mat-button-toggle>
      </mat-button-toggle-group>
      </div>

    </div>
  <ng-container>
    <mat-card class="result-card" *ngFor="let resView of results">
      <mat-card-title class="result-title" *ngIf="resView.name">
        {{ resView.name }}
      </mat-card-title>
        <mat-card-content>
          <mat-table [dataSource]="resView.results">
            <ng-container matColumnDef="place">
              <mat-header-cell class="place" *matHeaderCellDef translate> Place</mat-header-cell>
              <mat-cell class="place" *matCellDef="let element">
                <span class="mobile-label" translate>Place</span>
                {{element.place}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="cat-place">
              <mat-header-cell class="place" *matHeaderCellDef translate> Place</mat-header-cell>
              <mat-cell class="place" *matCellDef="let element">
                <span class="mobile-label" translate>Place</span>
                {{element.categoryPlace}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="startnumber">
              <mat-header-cell class="startnumber" *matHeaderCellDef translate>StartNumber</mat-header-cell>
              <mat-cell class="startnumber" *matCellDef="let element">
                <span class="mobile-label" translate>StartNumber</span>
                {{element.startNumber}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef translate> Name</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span class="mobile-label" translate>Name</span>
                {{element.fullname}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="birthdate">
              <mat-header-cell class="birthdate  align-right" *matHeaderCellDef translate> Birthdate</mat-header-cell>
              <mat-cell class="birthdate align-right" *matCellDef="let element">
                <span class="mobile-label" translate>Birthdate</span>
                {{ element.birthDate | date : 'yy' }}
                <span *ngIf="element.birthDate">' &nbsp;&nbsp;&nbsp;</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="city">
              <mat-header-cell *matHeaderCellDef translate> City</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span class="mobile-label" translate>City</span>
                {{ element.city }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="category">
              <mat-header-cell *matHeaderCellDef translate> Category</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span class="mobile-label" translate>Category</span>
                {{element.categoryPlace}}˚ {{element.categoryName }} </mat-cell>
            </ng-container>

            <ng-container  matColumnDef="finishTime">
              <mat-header-cell class="finishtime" *matHeaderCellDef translate> FinishTime</mat-header-cell>
              <mat-cell class="finishtime" *matCellDef="let element">
                <span class="mobile-label" translate>FinishTime</span>

                  {{ element.finishTime }}
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="visibleLabels[selectedView]"></mat-header-row>
            <mat-row *matRowDef="let row; columns: visibleLabels[selectedView];"></mat-row>
          </mat-table>

        </mat-card-content>
    </mat-card>
  </ng-container>
  </div>
</app-content>
