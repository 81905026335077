import { Component } from '@angular/core';
import {Observable} from "rxjs";
import {SpinnerService} from "./other/services/spinner.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css' ]
})
export class AppComponent {
  public spinnerText: Observable<string>;

  constructor(private spinnerService: SpinnerService) {
    this.spinnerText = this.spinnerService.text();
  }
}
