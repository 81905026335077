<div class="event-container" [class.padding]="componentPadding">
  <mat-card class="event-card" *ngFor="let ev of events">
    <mat-card-content class="subscribe-content" (click)="navigationService.addCurrentPage(ev.name + ' (' + datePipe.transform(ev.date, 'dd-MM-yyyy') + ')')"  [routerLink]="'/subscribe/' + ev.id">
        <div class="event-card-date">
          <strong>{{ev.date | date : 'dd-MM-yyyy'}}</strong>
        </div>
        <div class="event-card-name">
         <strong>{{ev.name}}</strong>
        </div>
        <div class="subscribe-button-container">
          <a mat-raised-button color="accent"><span translate>Subscribe</span>
          </a>
        </div>
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="!events || events.length == 0">
    <mat-card-header>
      <mat-card-title>
        <span translate>NoEventsAvailable</span>
      </mat-card-title>

    </mat-card-header>
    <mat-card-content>
      <p translate>ThereAreNoEventsAvailableWithYourSearchCriteria</p>
    </mat-card-content>
  </mat-card>
</div>
