import {Component, OnInit } from '@angular/core';
import {BibchipHttpService} from "../../../other/services/bibchip.http.service";
import {UserService} from "../../../other/services/user.service";
import {Router} from "@angular/router";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent, ConfirmDialogModel} from "../../../other/shared/confirm-dialog/confirm-dialog.component";
import {OrganiserManageEventComponent} from "./manage-event.component";
import {OrganiserEvent} from "../../../other/model/organiserEvent";
import {NavigationService} from "../../../other/services/navigation.service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-organiser-event-overview',
  templateUrl: './event-overview.component.html',
  styleUrls:['./event-overview.component.scss'],
  providers:[DatePipe]
})
export class OrganiserEventOverviewComponent implements OnInit {

  displayedColumns: string[] = [ 'name', 'date','isLive', 'subscriptionEnd', 'actions'];
  public events: MatTableDataSource<OrganiserEvent>;


  constructor(public dialog: MatDialog, private httpService: BibchipHttpService, private userService: UserService, private router: Router, public navigationService: NavigationService, public datePipe: DatePipe) {

  }

  add() {
    const dialogRef = this.dialog.open(OrganiserManageEventComponent);
    dialogRef.afterClosed().subscribe(x => { this.loadEvents(); });
  }

  deleteConfirmation(e: OrganiserEvent): void {

    const dialogData = new ConfirmDialogModel("ConfirmDelete","AreYouSureYouWantToDeleteX", e.name);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult)
        this.delete(e);
    });
  }

  edit(e: OrganiserEvent) {
    const dialogRef = this.dialog.open(OrganiserManageEventComponent);
    dialogRef.componentInstance.loadEvent(e);
    dialogRef.afterClosed().subscribe(x => { this.loadEvents(); });
  }

  delete(e: OrganiserEvent): void {
    this.httpService.delete("organiser/events/" + e.id).subscribe(x => {
      this.loadEvents();
    });
  }

  loadEvents() {
    this.httpService.get<OrganiserEvent[]>("organiser/events").subscribe(x => {
      this.events = new MatTableDataSource<OrganiserEvent>(x);
    });
  }

  ngOnInit(): void {
    this.loadEvents();
  }
}
