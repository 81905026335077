import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {BibchipHttpService} from "../../../other/services/bibchip.http.service";
import {UserService} from "../../../other/services/user.service";
import {MatDialogRef} from "@angular/material/dialog";
import {OrganiserEvent, PaymentMethod} from "../../../other/model/organiserEvent";
import {TranslateService} from "@ngx-translate/core";
import {PaymentMethodItem, PaymentService} from "../../../other/services/payment.service";

@Component({
  selector: 'app-organiser-manage-event',
  templateUrl: './manage-event.component.html',
  styleUrls:['./manage-event.component.scss']
})
export class OrganiserManageEventComponent implements OnInit {

  public event: OrganiserEvent;
  public paymentMethods: PaymentMethodItem[];
  selectedMethods: number[];

  constructor(private translateService: TranslateService, private paymentService: PaymentService, public dialogRef: MatDialogRef<OrganiserManageEventComponent>, private httpService: BibchipHttpService, private userService: UserService, private router: Router) {
    this.event = new OrganiserEvent();
    this.event.organiserEmail = this.userService.currentUser().email;
    this.paymentMethods = paymentService.getPaymentMethods(true);
    this.selectedMethods = [];
  }

  ngOnInit(): void {

  }

  loadEvent(e: OrganiserEvent): void {
    this.event = e;
    if(this.event.paymentMethod) {
      this.selectedMethods = this.paymentService.getMethodsFromEnum(this.event.paymentMethod);
    }
  }

  add() : void {
    if(!this.event.id || this.event.id == 0) {
      this.httpService.post("organiser/events", this.event).subscribe(x => {
        this.dialogRef.close();
      });
    } else {
      this.httpService.put("organiser/events/" + this.event.id, this.event).subscribe(x => {
        this.dialogRef.close();
      });
    }
  }

  paymentMethodChanged() {
    if(this.selectedMethods) {
      this.event.paymentMethod = this.selectedMethods.reduce((a,b) => a + b, 0);
    } else {
      this.event.paymentMethod = null;
    }
  }
}
