export class UserBase {
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  refreshToken: string;
  refreshTokenExpiresAt: string;
  roles: string[];

  constructor() {
    this.roles = [];
  }
}
