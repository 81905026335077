import {Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation} from '@angular/core';
import {BibchipHttpService} from "../../other/services/bibchip.http.service";
import {UserService} from "../../other/services/user.service";
import {Router} from "@angular/router";
import {UserBase} from "../../other/model/user.base";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatDialog} from "@angular/material/dialog";
import {ManageUserComponent} from "./manage-user.component";
import {ConfirmDialogComponent, ConfirmDialogModel} from "../../other/shared/confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-admin-users-overview',
  templateUrl: './users-overview.component.html',
  styleUrls:['./users-overview.component.scss']
})
export class UsersOverviewComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  displayedColumns: string[] = ['email', 'refreshtokenExpire', 'roles', 'actions'];
  public users: MatTableDataSource<UserBase>;
  public userId: number;


  constructor(public dialog: MatDialog, private httpService: BibchipHttpService, private userService: UserService, private router: Router) {
      this.userId = this.userService.currentUser().userId;
  }

  addUser() {
    const dialogRef = this.dialog.open(ManageUserComponent);
    dialogRef.afterClosed().subscribe(x => { this.loadUsers(); });
  }

  generatePasswordConfirmation(u: UserBase): void {
    const dialogData = new ConfirmDialogModel("ConfirmGenerateNewPassword","AreYouSureYouWantToDGenerateANewPassword", null);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(x => {
      if(x) {
        this.httpService.put("users/" + u.id + "/generate-password", null).subscribe(x => {
          alert((<any> x).password);
        });
      }
    });
  }

  deleteConfirmation(u: UserBase): void {

    const dialogData = new ConfirmDialogModel("ConfirmDelete","AreYouSureYouWantToDeleteX", u.email);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult)
        this.deleteUser(u);
    });
  }

  editUser(u: UserBase) {
    const dialogRef = this.dialog.open(ManageUserComponent);
    dialogRef.componentInstance.loadUser(u);
    dialogRef.afterClosed().subscribe(x => { this.loadUsers(); });
  }

  deleteUser(u: UserBase): void {
    this.httpService.delete("users/" + u.id).subscribe(x => {
      this.loadUsers();
    });
  }

  loadUsers() {
    this.httpService.get<UserBase[]>("users").subscribe(x => {
      this.users = new MatTableDataSource<UserBase>(x);
      this.users.paginator = this.paginator;
    });
  }

  ngOnInit(): void {
    this.loadUsers();
  }
}
