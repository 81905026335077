<h2 mat-dialog-title>
  <span translate>CostsAndProfits</span>
</h2>
<mat-dialog-content class="mat-typography">
  <div class="big">
    <table>
      <tr>
        <th class="desc" translate>Description</th>
        <th class="quantity" translate>Quantity</th>
        <th class="price" translate>Price</th>
        <th class="price" translate>Total</th>
      </tr>
        <tr>
          <td class="title" colspan="4" translate>Costs</td>
        </tr>
      <tr>
        <td class="sub-1" colspan="3" translate>GeneralCost</td>
        <td class="price">{{ profit?.costs.defaultSubscriptionCost | currency: 'EUR': 'symbol': '.2-2'}}</td>
      </tr>
      <tr>
        <td class="sub-1" colspan="4" translate>CostPerSubscriptionType</td>
      </tr>
      <tr *ngFor="let subCost of profit?.costs.participantTypeCosts">
        <td class="sub-2" translate>{{ getPaymentMethodString(subCost.paymentMethod) }}</td>
        <td class="quantity">{{subCost.numberOfParticipants}}</td>
        <td class="price">{{subCost.costForOneParticipant | currency: 'EUR': 'symbol': '.2-2'}}</td>
        <td class="price">{{ subCost.total | currency: 'EUR': 'symbol': '.2-2'}}</td>
      </tr>
      <tr>
        <td colspan="3" translate>TotalCost</td>
        <td class="price bold">{{ profit?.costs.total | currency: 'EUR': 'symbol': '.2-2'}}</td>
      </tr>
      <tr>
        <td colspan="4">&nbsp;</td>
      </tr>
      <tr>
      <td class="title" colspan="4" translate>Profits</td>
    </tr>
      <ng-container *ngFor="let prof of profit?.profits.profit">
        <tr>
          <td class="sub-1" translate>Price</td>
          <td class="quantity">{{ prof.quantity }}</td>
          <td class="price">{{ prof.price | currency: 'EUR': 'symbol': '.2-2'}}</td>
          <td class="price">{{ prof.total | currency: 'EUR': 'symbol': '.2-2'}}</td>
        </tr>
        <tr>
          <td class="sub-2" translate>Paid</td>
          <td class="quantity">{{ prof.paidQuantity }}</td>
          <td class="price">{{ prof.price | currency: 'EUR': 'symbol': '.2-2'}}</td>
          <td class="price">{{ prof.paidTotal | currency: 'EUR': 'symbol': '.2-2'}}</td>
        </tr>
        <tr>
          <td class="sub-2" translate>Unpaid</td>
          <td class="quantity">{{ prof.unpaidQuantity }}</td>
          <td class="price">{{ prof.price | currency: 'EUR': 'symbol': '.2-2'}}</td>
          <td class="price">{{ prof.unpaidTotal | currency: 'EUR': 'symbol': '.2-2'}}</td>
        </tr>
        <tr>
          <td colspan="4">&nbsp;</td>
        </tr>
      </ng-container>
      <tr>
        <td colspan="3" translate>TotalProfit</td>
        <td class="price bold">{{ profit?.profits.total | currency: 'EUR': 'symbol': '.2-2'}}</td>
      </tr>
      <tr>
        <td colspan="4">&nbsp;</td>
      </tr>
      <tr>
        <td class="title" colspan="3" translate>Total</td>
        <td class="price bold">{{profit?.total | currency: 'EUR': 'symbol': '.2-2'}}</td>
      </tr>
    </table>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close><span translate>Close</span></button>
</mat-dialog-actions>
