import {Component, OnDestroy, OnInit } from '@angular/core';
import {BibchipHttpService} from "../../../other/services/bibchip.http.service";
import {UserService} from "../../../other/services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent, ConfirmDialogModel} from "../../../other/shared/confirm-dialog/confirm-dialog.component";
import {OrganiserEvent} from "../../../other/model/organiserEvent";
import {NavigationService} from "../../../other/services/navigation.service";
import {DatePipe} from "@angular/common";
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {ExtraFieldType, OrganiserExtraField} from "../../../other/model/organiserExtraField";
import {OrganiserManageExtraFieldComponent} from "./manage-extra-field.component";
import {OrganiserCoupleExtraFieldComponent} from "./couple-extra-field.component";

@Component({
  selector: 'app-organiser-extra-field-overview',
  templateUrl: './extra-field-overview.component.html',
  styleUrls:['./extra-field-overview.component.scss'],
  providers:[DatePipe]
})
export class OrganiserExtraFieldOverviewComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = [ 'name', 'isRequired', 'dependentOn', 'actions'];
  public extraFields: MatTableDataSource<OrganiserExtraField>;
  public event: OrganiserEvent;
  private subscriptions: Subscription[];

  constructor(public dialog: MatDialog, private httpService: BibchipHttpService, private userService: UserService, private activatedRoute: ActivatedRoute, private router: Router, public navigationService: NavigationService, private datePipe: DatePipe, private translateService: TranslateService) {
    this.subscriptions = [];
  }

  add() {
    const dialogRef = this.dialog.open(OrganiserManageExtraFieldComponent);
    let ef = new OrganiserExtraField();
    ef.type = ExtraFieldType.Text;
    dialogRef.componentInstance.loadExtraField(ef, this.event.id);
    dialogRef.afterClosed().subscribe(x => { this.loadExtraFields(); });
  }

  deleteConfirmation(item: OrganiserExtraField): void {

    const dialogData = new ConfirmDialogModel("ConfirmDelete","AreYouSureYouWantToDeleteX", item.name);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult)
        this.delete(item);
    });
  }

  edit(item: OrganiserExtraField) {
    const dialogRef = this.dialog.open(OrganiserManageExtraFieldComponent);
    dialogRef.componentInstance.loadExtraField(item, this.event.id);
    dialogRef.afterClosed().subscribe(x => { this.loadExtraFields(); });
  }

  couple(item: OrganiserExtraField) {
    const dialogRef = this.dialog.open(OrganiserCoupleExtraFieldComponent);
    dialogRef.componentInstance.loadExtraField(item, this.event.id);
    dialogRef.afterClosed().subscribe(x => { this.loadExtraFields(); });
  }


  delete(item: OrganiserExtraField): void {
    this.httpService.delete("organiser/events/" + this.event.id + '/extra-fields/' + item.id).subscribe(x => {
      this.loadExtraFields();
    });
  }

  loadExtraFields() {
    this.httpService.get<OrganiserExtraField[]>("organiser/events/" + this.event.id + "/extra-fields").subscribe(x => {
      this.extraFields = new MatTableDataSource<OrganiserExtraField>(x);
    });
  }

  ngOnInit(): void {
    this.subscriptions.push(this.activatedRoute.paramMap.subscribe(x => {
      let evId = x.get('id');
      this.httpService.get<OrganiserEvent>('organiser/events/' + evId).subscribe(y => {
        this.event = y;
        this.loadExtraFields();
         this.navigationService.lastNavigationAction().subscribe(link => {
          if(!link) {
            this.navigationService.addNavigationAction('/organiser/events/' + evId + '/dashboard', y.name + ' (' + this.translateService.instant('ExtraFields') + ')');
          }
        }).unsubscribe();
      });
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  moveDown(item: OrganiserExtraField) {
    this.httpService.put("organiser/events/" + this.event.id + '/extra-fields/' + item.id + "/move-down", null).subscribe(x => {
      this.loadExtraFields();
    });
  }

  moveUp(item: OrganiserExtraField) {
    this.httpService.put("organiser/events/" + this.event.id + '/extra-fields/' + item.id + "/move-up", null).subscribe(x => {
      this.loadExtraFields();
    });
  }

  canNotMove(item: OrganiserExtraField, override: boolean) {
    if(override)
      return override;
    if(item.onlyVisibleWhenThisIsFilledIn)
      return  true;
    return this.extraFields.data.filter(y => y.onlyVisibleWhenThisIsFilledIn != null && y.onlyVisibleWhenThisIsFilledIn.id == item.id).length > 0;
  }
}
