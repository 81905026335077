<h2 mat-dialog-title>
  <span translate>Couple</span>
</h2>
<mat-dialog-content class="mat-typography">
  <form class="example-form" #fieldForm="ngForm">
    <div class="example-full-width" *ngFor="let comp of competitions">
      <mat-checkbox (change)="toggleCompStatus($event,comp)" [indeterminate]="comp.checked === null" [(ngModel)]="comp.checked"  name="comp-{{comp.id}}">
        {{ comp.name }}
      </mat-checkbox>
        <div style="margin-left: 20px">
          <div class="example-full-width" *ngFor="let cat of findCategories(comp)">
            <mat-checkbox (change)="toggleCatStatus(comp)" name="cat-{{cat.id}}" [(ngModel)]="cat.checked">
              {{cat.name}}
            </mat-checkbox>
          </div>
        </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close><span translate>Cancel</span></button>
  <button mat-button [disabled]="!fieldForm.valid" (click)="save()" cdkFocusInitial>
    <span translate>Save</span>
  </button>
</mat-dialog-actions>
