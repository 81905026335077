<h2 mat-dialog-title>
  <span translate>Publish</span>
</h2>
<mat-dialog-content class="mat-typography">
  <div class="big">

    <div *ngIf="allDone(checklist)" class="warning">
      <mat-icon class="warning-icon">warning</mat-icon> <div class="warning-title" translate>Warning</div>
      <p translate>PublishWarning</p>
    </div>

      <div class="checklist-item" [ngClass]="{'done':item.done}" *ngFor="let item of checklist">
        <mat-icon class="checklist-item-icon" [ngClass]="{'toggle':item.canBeOverridden}" *ngIf="item.done" (click)="toggle(item)">done</mat-icon>
        <mat-icon class="checklist-item-icon" [ngClass]="{'toggle':item.canBeOverridden}" *ngIf="!item.done" (click)="toggle(item)">clear</mat-icon>
        <div class="checklist-item-title"> {{item.description | translate}} </div>
        <div class="checklist-item-content">
          <p *ngIf="item.explanation">
            {{ item.explanation | translate }}
          </p>
          <p [ngClass]="{'toggle':item.canBeOverridden}" (click)="toggle(item)" *ngIf="item.canBeOverridden">
            <span translate>ClickHereToOverrideThis</span>
          </p>
        </div>
      </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close><span translate>Close</span></button>
  <button mat-button mat-dialog-close [disabled]="!allDone(checklist)" (click)="publish()"><span translate>Publish</span></button>
</mat-dialog-actions>
