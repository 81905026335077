<div class="table-container">
  <mat-table [dataSource]="users">
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label" translate>Id</span>
        {{element.id}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="firstname">
      <mat-header-cell *matHeaderCellDef translate> Firstname</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label" translate>Firstname</span>
        {{element.firstname}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastname">
      <mat-header-cell *matHeaderCellDef translate> Lastname</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label" translate>Lastname</span>
        {{element.lastname}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef translate> Email</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label" translate>Email</span>
        {{element.email}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="refreshtokenExpire">
      <mat-header-cell *matHeaderCellDef translate> TokenExpiration</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label" translate>TokenExpiration</span>
        {{element.refreshTokenExpiresAt | date: 'yyyy-MM-dd HH:mm:ss'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="roles">
      <mat-header-cell *matHeaderCellDef translate> Roles</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label" translate>Roles</span>
        <div class="roles" *ngFor="let role of element.roles">
          {{ 'Role.' + role | translate }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef translate> Actions</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label" translate>Actions</span>
        <span *ngIf="userId != element.id">
        <button matTooltip="{{ 'Edit' | translate }}" mat-icon-button (click)="editUser(element)">
          <mat-icon>edit</mat-icon>
        </button>
           <button  matTooltip="{{ 'GeneratePassword' | translate }}" mat-icon-button (click)="generatePasswordConfirmation(element)">
          <fa-icon class="fa-20px" icon="unlock-alt"></fa-icon>
        </button>
        <button  matTooltip="{{ 'Delete' | translate }}" mat-icon-button (click)="deleteConfirmation(element)">
          <mat-icon>delete</mat-icon>
        </button>
          </span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

<button class="add-user" mat-raised-button color="primary" (click)="addUser()">{{ 'AddX' | translate : { x: 'User' | translate } }}</button>
